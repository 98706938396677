import styled from "styled-components";

const SummaryItem = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #dee2e6;

  .receipt-attached {
    color: ${({ theme }) => theme["primary-color"]};
  }

  p {
    margin-bottom: 0;
    &:first-child {
      width: 200px;
    }

    &:last-child {
      font-weight: bold;
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  .view-cart-btn {
    width: 140px;
  }
`;

const StyledHeader = styled.div<{ borderless?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  ${({ borderless }) => !borderless && `border-bottom: 1px solid #dee2e6`}

  & .close-btn {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
  }

  button,
  .close-btn {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
  }
`;

const SuccessWrapper = styled.div`
  text-align: center;
  margin-top: 2rem;

  .success-icon {
    margin-bottom: 1rem;
  }
`;

export { SummaryItem, ButtonWrapper, StyledHeader, SuccessWrapper };
