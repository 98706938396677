import React from 'react'
import styled from 'styled-components';

const TermsAndConditions = () => {
  return (
    <Section id="terms_&_cond">TermsAndConditions</Section>
  )
}

export default TermsAndConditions;

const Section = styled.section`
  display: flex;
`;