import React, { useState } from "react";
import { Drawer } from "antd";
import SummaryContent from "./summaryContent";
import SuccessContent from "./successContent";

type SummaryDrawerProps = {
  visible: boolean;
  onClose: () => void;
  reset: () => void;
  resetFields: () => void;
};

const SummaryDrawer = ({
  visible,
  onClose,
  reset,
  resetFields,
}: SummaryDrawerProps) => {
  const [addedToCart, setAddedToCart] = useState(false);

  return (
    <Drawer
      placement="right"
      width={600}
      closable={false}
      onClose={onClose}
      visible={visible}
    >
      {addedToCart ? (
        <SuccessContent onClose={onClose} reset={reset} />
      ) : (
        <SummaryContent
          resetFields={resetFields}
          onClose={onClose}
          setAddedToCart={setAddedToCart}
        />
      )}
    </Drawer>
  );
};

export default SummaryDrawer;
