const primaryColor = "#3879F8";
const secondaryColor = "";
const textPlaceholder = "rgba(0, 0, 0, 0.6)";
const darkColor = "#000000";
const successColor = "#20A474";
const warningColor = "#E7833B"; // warning
const warningHover = "#E7833B";
const errorColor = "#FA4339"; // error
const errorHover = "#FA4339";
const infoColor = "#2C99FF"; // info
const infoHover = "#0D79DF";

const labelColor = "#9A9A9A";

// Links
const linkColor = "#0D79DF";
const linkHover = "#0D79DF";

// Select Item
const selectItemSelectedBgColor = "#F5F6F8;";
const selectItemSelectedColor = primaryColor;

// ================ Fonts ================
const fontSizeBase = "16px";
const fontSizeSm = "12px";

// ================ Borders ================
const borderColorLight = "#F1F2F6";
const borderColorNormal = "#DBDEE7";
const borderColorDeep = "#C6D0DC";

// ================ Buttons ================
const btnbase = "60px";
const btnlg = "60px";
const btnsm = "42px";
const borderRadiusBase = "6px";

// ================ Input ================
const inputHeightBase = "60px";
const inputHeightLg = "50px";
const inputHeightSm = "40px";

const theme = {
  "primary-color": primaryColor, // primary color for all components
  "info-color": infoColor,
  "success-color": successColor,

  "text-placeholder": textPlaceholder,

  // ================ Fonts ================
  "font-family": "'Roboto', sans-serif",
  "font-size-base": fontSizeBase,
  "font-size-sm": fontSizeSm,

  // ================ Checkbox ================
  "checkbox-size": "14px",
  "checkbox-border-radius": "2px",
  "checkbox-group-item-margin-right": "8px",

  // ================ Buttons ================
  "btn-height-lg": btnlg,
  "btn-height-sm": btnsm,
  "btn-height-base": btnbase,
  "btn-square-size": btnbase,
  "btn-square-size-lg": btnlg,
  "btn-square-size-sm": btnsm,
  "btn-default-color": darkColor,
  "btn-border-radius-base": borderRadiusBase,
  "btn-border-radius-sm": borderRadiusBase,

  // ================ Cards ================
  "card-head-background": "#ffffff",
  "card-head-color": darkColor,
  "card-background": "#ffffff",
  "card-head-padding": "16px",
  "card-padding-base": "12px",
  "card-radius": "10px",
  "card-shadow": "0 5px 20px rgba",

  // Form
  // "label-required-color": highlightColor,
  "label-color": labelColor,
  "form-item-label-font-size": fontSizeSm,

  // ================ Input ================
  "input-height-base": inputHeightBase,
  "input-border-color": borderColorNormal,
  "input-height-sm": inputHeightSm,
  "input-height-lg": inputHeightLg,
  "input-border-radius-base": borderRadiusBase,

  "drawer-header-padding": "38px 45px",
  "drawer-body-padding": "45px",
  "drawer-bg": "#fff",
  // "drawer-footer-padding-vertical": @modal-footer-padding-vertical,
  // "drawer-footer-padding-horizontal": @modal-footer-padding-horizontal,
  // "drawer-header-close-size": "56px",

  // Select
  // "select-border-color": "",
  "select-item-selected-color": selectItemSelectedColor,
  "select-item-selected-font-weight": 400,
  "select-dropdown-bg": "#ffffff",
  "select-item-selected-bg": selectItemSelectedBgColor,
  "select-item-active-bg": selectItemSelectedBgColor,
  "select-dropdown-vertical-padding": "19px",
  "select-dropdown-font-size": fontSizeBase,
  "select-dropdown-line-height": "24px",
  "select-dropdown-height": "54px",
  // "select-background": "",
  // "select-clear-background": "",
  // "select-selection-item-bg": "",
  // "select-selection-item-border-color": "",
  // "select-single-item-height-lg": "40px",

  // Collapse
  "collapse-panel-border-radius": borderRadiusBase,
  "collapse-header-padding": "18px 30px",
  "collapse-header-padding-extra": "40px",
  "collapse-header-bg": "#fff",
  "collapse-content-padding": "16px 32px",
  "collapse-content-bg": "#fff",
  "collapse-header-arrow-left": "16px",

  // Alert
  "alert-success-border-color": successColor,
  "alert-success-bg-color": successColor + 15,
  "alert-error-bg-color": errorColor + 15,
  "alert-warning-bg-color": warningColor + 15,
  "alert-info-bg-color": infoColor + 15,

  // radio btn
  "radio-button-checked-bg": primaryColor,

  // gutter width
  "grid-gutter-width": 25,

  // skeleton
  "skeleton-color": borderColorLight,
};

export default theme;
