import React, { createContext, FC, useContext, useReducer } from "react";
import reducer, { initialState } from "./reducer";
import { CtxProps } from "./types";

const CartContext = createContext<CtxProps | undefined>(undefined);

const CartProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const removeItemFromCart = (itemIndex: number) => {
    dispatch({
      type: "REMOVE_ITEM_FROM_CART",
      payload: itemIndex,
    });
  };

  const value = {
    state,
    dispatch,
    removeItemFromCart,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export function useCartContext() {
  const context = useContext(CartContext);

  if (context === undefined) {
    throw new Error("useCartContext must be used within a CartProvider");
  }

  return context;
}

export default CartProvider;
