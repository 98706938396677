import { Button, ButtonProps } from "antd";
import { ReactNode } from "react";
import styled from "styled-components";

const ButtonStyled = ({
  children,
  suffixIcon,
  full,
  ...rest
}: ButtonProps & { full?: boolean; suffixIcon?: ReactNode }) => {
  return (
    <ButtonWraper full={full} {...rest}>
      {children}
      {suffixIcon ? <div className="suffix-icon">{suffixIcon}</div> : null}
    </ButtonWraper>
  );
};

const ButtonWraper = styled(Button)<{ full?: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: ${({ theme }) => theme["btn-height-base"]};
  border-radius: ${({ theme }) => theme["btn-border-radius-base"]};
  /* ${(props) => props.full && `width: 100%`} */

  .suffix-icon {
    position: absolute;
    right: 12px;

    * {
      margin: 0;
    }
  }

  .link-to-signup {
    width: 70%;
  }
`;

export default ButtonStyled;
