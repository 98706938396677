import Calendar, { CalendarProps } from "react-calendar";
import styled from "styled-components";

const minDate = new Date();

const CustomCalendar = ({ value, onChange, ...rest }: CalendarProps) => {
  return (
    <CalendarWrapper>
      <Calendar
        onChange={onChange}
        value={value}
        tileDisabled={({ activeStartDate, date, view }) => date.getDay() === 0}
        minDate={minDate}
        {...rest}
      />
    </CalendarWrapper>
  );
};

export default CustomCalendar;

const CalendarWrapper = styled.div`
  .react-calendar {
    width: 100%;
    line-height: 1.125em;

    &--doubleView {
      width: 700px;

      .react-calendar__viewContainer {
        display: flex;
        margin: -0.5em;

        > * {
          width: 50%;
          margin: 0.5em;
        }
      }
    }

    &,
    & *,
    & *:before,
    & *:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    button {
      margin: 0;
      border: 0;
      outline: none;

      &:enabled {
        &:hover {
          cursor: pointer;
        }
      }
    }

    &__navigation {
      display: flex;
      height: 44px;
      border-bottom: 1px solid #dee2e6;

      button {
        min-width: 44px;
        background: none;

        &:disabled {
          background-color: #f0f0f0;
        }

        &:enabled {
          &:hover,
          &:focus {
            background-color: #e6e6e6;
          }
        }
      }
    }

    &__viewContainer {
      padding-top: 1rem;
    }

    &__month-view {
      &__weekdays {
        text-align: center;
        text-transform: uppercase;
        color: #adb5bd;
        font-weight: bold;
        padding-bottom: 10px;

        &__weekday {
          padding: 0.5em;
        }
      }

      &__weekNumbers {
        .react-calendar__tile {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.75em;
          font-weight: bold;
        }
      }

      &__days {
        &__day {
          &--weekend {
            color: #d10000;
          }

          &--neighboringMonth {
            color: #ced4da;
          }
        }
      }
    }

    &__year-view,
    &__decade-view,
    &__century-view {
      .react-calendar__tile {
        padding: 2em 0.5em;
      }
    }

    &__tile {
      max-width: 100%;
      padding: 10px 6.6667px;
      background: none;
      text-align: center;
      line-height: 40px;

      &:disabled {
        background-color: #f0f0f0;
      }

      &:enabled {
        &:hover,
        &:focus {
          background-color: #e6e6e6;
          border-radius: 50px;
        }
      }

      &--now {
        background: #ffff76;

        &:enabled {
          &:hover,
          &:focus {
            background: #ffffa9;
          }
        }
      }

      &--hasActive {
        background: #76baff;
        border-radius: 50px;

        &:enabled {
          &:hover,
          &:focus {
            background: #a9d4ff;
          }
        }
      }

      &--active {
        background: #006edc;
        border-radius: 50px;
        color: white;

        &:enabled {
          &:hover,
          &:focus {
            background: #1087ff;
          }
        }
      }
    }

    &--selectRange {
      .react-calendar__tile {
        &--hover {
          background-color: #e6e6e6;
        }
      }
    }
  }
`;
