import styled from "styled-components";

const Section = styled.section`
  padding: 3rem 0 30rem;

  .max-width-wrapper {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1.5rem;

    .title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 3rem;

      .title {
        margin-bottom: 0;
      }
    }

    .book-now-btn {
      width: 200px;
      margin-top: 3rem;
    }
  }
`;

const CollapseWrapper = styled.div`
  position: relative;
`;

const DeleteCartItemBtn = styled.button`
  background: none;
  border: none;
  display: flex;
  color: ${({ theme }) => theme["primary-color"]};
  cursor: pointer;
  padding: 1rem;
  position: absolute;
  right: -5rem;
  top: 15px;
  align-items: center;
  justify-content: center;
`;

export { Section, CollapseWrapper, DeleteCartItemBtn };
