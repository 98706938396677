import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Section } from "pages/dashboard/styles";
import Heading from "components/common/heading";
import api from "api";
import { Form, Modal } from "antd";
import ButtonStyled from "components/common/buttonStyled";
import InputStyled from "components/common/inputStyled";
import CustomError from "components/common/customError";

const FormItem = Form.Item;

const ConfirmPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiErrors, setApiErrors] = useState<string[]>([]);

  const [form] = Form.useForm();
  const params = useParams();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const { token } = params as { token: string };

      const values = (await form.validateFields()) as { new_password: string };

      setIsLoading(true);

      const response = await api.auth.forgotPasswordConfirm({
        token,
        ...values,
      });

      console.log(response);

      if (response.status >= 200) {
        Modal.success({
          title: response.data.message,
          content: "Password changed successfully.",
          okText: "Login",
          onOk: () => navigate("/login"),
        });
      }

      setIsLoading(false);
    } catch (err: any) {
      console.log(err);
      setIsLoading(false);

      let errorMsg: string[] = [];

      Object.values(err.response.data).forEach((error) => {
        if (Array.isArray(error)) {
          error.forEach((e) => errorMsg.push(e));
        }

        if (typeof error === "string") {
          errorMsg.push(error);
        }
      });

      setApiErrors(errorMsg);
    }
  };

  return (
    <Section>
      <div className="max-width-wrapper">
        <Heading className="title">Enter New Password</Heading>

        <Form
          name="change_password"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <FormItem
            label="New Password"
            name="password"
            rules={[{ message: "Please input a password", required: true }]}
          >
            <InputStyled.Password placeholder="Password" />
          </FormItem>

          <FormItem className="btn-signin-wrapper">
            <ButtonStyled
              className="btn-signin"
              htmlType="submit"
              type="primary"
              size="large"
              full
              loading={isLoading}
            >
              Change Password
            </ButtonStyled>
          </FormItem>
        </Form>

        {apiErrors.length > 0 ? (
          <CustomError>
            {apiErrors.map((error, i) => (
              <li key={error.substr(0, 4) + i} className="text-sm">
                {error}
              </li>
            ))}
          </CustomError>
        ) : null}
      </div>
    </Section>
  );
};

export default ConfirmPassword;
