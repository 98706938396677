import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "assets/svgs/close-icon.svg";
import Heading from "../heading";
import ButtonStyled from "../buttonStyled";
import { StyledHeader, ButtonWrapper, SummaryItem } from "./styles";
import { Modal } from "antd";
import { useCartContext } from "context/cart";
import api from "api";
import { useNavigate } from "react-router-dom";

const SummaryContent = ({
  onClose,
  setAddedToCart,
  resetFields,
}: {
  onClose: () => void;
  resetFields: () => void;
  setAddedToCart: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const {
    state: { summaryDetails },
    dispatch,
  } = useCartContext();

  const handleBooking = async () => {
    try {
      const payload = {
        shipping_company: summaryDetails.shippingLine?.id,
        bay_area: summaryDetails.bay_area?.id,
        containers: [
          {
            date: summaryDetails.date,
            laden_number: summaryDetails.laden_number,
            container_number: summaryDetails.container_number,
            container_size: summaryDetails.container_size,
          },
        ],
      };

      setLoading(true);

      const response = await api.booking.createBooking(payload);

      console.log(response);

      setLoading(false);

      if (response.status === 201) {
        Modal.success({
          title: response.data.message,
          onOk: () => {
            onClose();

            dispatch({
              type: "RESET",
              payload: {},
            });

            navigate("/drop-off");
          },
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleAddToCart = () => {
    setAddedToCart((open) => !open);

    const cartData = JSON.parse(localStorage.getItem("cartData")!) || [];
    localStorage.setItem(
      "cartData",
      JSON.stringify([...cartData, summaryDetails!])
    );

    dispatch({
      type: "ADD_TO_CART",
      payload: summaryDetails!,
    });

    resetFields();
  };

  return (
    <>
      <StyledHeader>
        <Heading as="H2">Summary</Heading>

        <button className="close-btn" onClick={onClose}>
          <CloseIcon />
        </button>
      </StyledHeader>

      <SummaryItem>
        <p>Shipping Line:</p>
        <p>{summaryDetails?.shippingLine?.name}</p>
      </SummaryItem>

      <SummaryItem>
        <p>Holding Bay:</p>
        <p>{summaryDetails?.bay_area?.name}</p>
      </SummaryItem>

      <SummaryItem>
        <p>Anticipated drop off date:</p>
        <p>{summaryDetails?.date}</p>
      </SummaryItem>

      <SummaryItem>
        <p>Bill of Laden:</p>
        <p>{summaryDetails?.laden_number}</p>
      </SummaryItem>

      <SummaryItem>
        <p>Container Number:</p>
        <p>{summaryDetails?.container_number}</p>
      </SummaryItem>

      <SummaryItem>
        <p>Container Size:</p>
        <p>{summaryDetails?.container_size}</p>
      </SummaryItem>

      <ButtonWrapper className="btn-wrapper">
        <ButtonStyled type="primary" ghost onClick={() => handleAddToCart()}>
          Add To Cart
        </ButtonStyled>
        <ButtonStyled loading={loading} type="primary" onClick={handleBooking}>
          Book Now
        </ButtonStyled>
      </ButtonWrapper>
    </>
  );
};

export default SummaryContent;
