import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";
import api from "api";
import { BayAreaProps, ShippingLineProps } from "api/booking";
import { useAuthContext } from "context/auth";

export type DemurageSize = {
  id: string;
  size: string;
  container_type?: string;
  free_days: number;
  date_created: string;
};

type IBookingStateProps = {
  isLoading: boolean;
  shippingData: ShippingLineProps[];
  bayAreaData: BayAreaProps[];
  demurageSizes: DemurageSize[];
  //   refetchShippingData: () => void;
  //   refetchBayAreaData: () => void;
};

const BookingContext = createContext<IBookingStateProps | undefined>(undefined);

const BookingProvider: FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [shippingData, setShippingData] = useState<ShippingLineProps[]>([]);
  const [bayAreaData, setBayAreaData] = useState<BayAreaProps[]>([]);
  const [demurageSizes, setDemurageSizes] = useState([]);

  const { isAuthenticated } = useAuthContext();

  const fetchShippingCompanies = async () => {
    try {
      // setIsLoading(false);
      const resp = await api.booking.getShippingCompanies();

      setIsLoading(true);

      setShippingData(resp.data.data);
    } catch (error) {
      // setIsLoading(false);

      console.log(error);
    }
  };

  const fetchBayAreas = async () => {
    try {
      // setIsLoading(true);

      const resp = await api.booking.getBayAreas();

      // setIsLoading(false);

      setBayAreaData(resp.data.data);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const fetchDemurageSizes = async () => {
    try {
      // setIsLoading(false);
      const resp = await api.booking.getDemurageSizes();

      setIsLoading(true);
      const sizes = resp.data.data?.sort((a: any, b: any) =>
        a.size.localeCompare(b.size)
      );

      setDemurageSizes(sizes);
    } catch (error) {
      // setIsLoading(false);

      console.log(error);
    }
  };

  useEffect(() => {
    async function makeCalls() {
      if (isAuthenticated) {
        setIsLoading(true);

        // await fetchShippingCompanies();
        // await fetchBayAreas();
        // await fetchDemurageSizes();

        await Promise.all([
          fetchShippingCompanies(),
          fetchBayAreas(),
          fetchDemurageSizes(),
        ]);

        setIsLoading(false);
      }
    }

    makeCalls();
  }, [isAuthenticated]);

  return (
    <BookingContext.Provider
      value={{ isLoading, shippingData, bayAreaData, demurageSizes }}
    >
      {children}
    </BookingContext.Provider>
  );
};

export function useBookingContext() {
  const context = useContext(BookingContext);

  if (context === undefined) {
    throw new Error(
      "useBookingContext should be used within a BookingProvider"
    );
  }

  const { isLoading, shippingData, bayAreaData, demurageSizes } = context;

  return {
    isLoading,
    shippingData,
    bayAreaData,
    demurageSizes,
  };
}

export default BookingProvider;
