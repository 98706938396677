import React from "react";
import { Drawer } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { StyledHeader } from "components/common/summaryDrawer/styles";
import { ReactComponent as CloseIcon } from "assets/svgs/close-icon.svg";
import { ReactComponent as AngleLeft } from "assets/svgs/angle-left.svg";

type SidebarDrawerProps = {
  visible: boolean;
  onClose: () => void;
};

const DrawerStyled = styled(Drawer)`
  .ant-drawer-body {
    padding: 1rem;

    .menu-wrapper {
      list-style: none;
      padding-left: 0;
      display: flex;
      flex-direction: column;

      li {
        padding-top: 0.5rem;
        padding-bottom: 1rem;

        a {
          display: flex;
          justify-content: space-between;
          align-items: center;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
`;

const SidebarDrawer = ({ visible, onClose }: SidebarDrawerProps) => {
  return (
    <DrawerStyled
      placement="right"
      width={300}
      closable={false}
      onClose={onClose}
      visible={visible}
      className="sidebar"
    >
      <StyledHeader borderless>
        <span></span>
        <button onClick={onClose} className="close-btn">
          <CloseIcon />
        </button>
      </StyledHeader>

      <ul className="menu-wrapper">
        <li>
          <Link onClick={onClose} to="/drop-off">
            <span>Dashboard</span>

            <AngleLeft />
          </Link>
        </li>
        <li>
          <Link onClick={onClose} to="/about">
            <span>About Us</span>

            <AngleLeft />
          </Link>
        </li>
        <li>
          <Link onClick={onClose} to="/calculate-demurrage">
            <span>Demurrage Calculator</span>

            <AngleLeft />
          </Link>
        </li>
        <li>
          <Link onClick={onClose} to="/contact">
            <span>Contact</span>

            <AngleLeft />
          </Link>
        </li>

        <li>
          <Link onClick={onClose} to="/cart">
            <span>Cart</span>

            <AngleLeft />
          </Link>
        </li>
      </ul>
    </DrawerStyled>
  );
};

export default SidebarDrawer;
