import React, { useState } from "react";
import { Form, notification } from "antd";
import Card from "components/common/card";
import InputStyled from "components/common/inputStyled";
import ButtonStyled from "components/common/buttonStyled";
import api from "api";
import { useNavigate } from "react-router-dom";

const FormItem = Form.Item;

const Security = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);

  const [form] = Form.useForm();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError([]);

      const values = (await form.validateFields()) as {
        current_password: string;
        new_password: string;
      };

      const payload = values;

      const response = await api.auth.changePassword(payload);

      setLoading(false);

      if (response.status === 200) {
        notification.success({
          message: "Pasword updated successfully.",
        });

      }
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      setError(error);
    }
    setLoading(true);
    setError([]);
  };

  return (
    <Card title="Manage your Password">
      <div className="view">
        <Form
          name="resetPassword"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <FormItem
            label="Current Password"
            name="current_password"
            rules={[{ message: "Please input your password", required: true }]}
          >
            <InputStyled.Password placeholder="Password" />
          </FormItem>

          <FormItem
            label="New Password"
            name="new_password"
            rules={[{ message: "Please input your password", required: true }]}
          >
            <InputStyled.Password placeholder="New password" />
          </FormItem>

          {/* <FormItem
            label="Confirm New Password"
            name="re_password"
            dependencies={["new_password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <InputStyled.Password placeholder="Confirm new password" />
          </FormItem> */}

          <ButtonStyled
            className="btn-save"
            htmlType="submit"
            type="primary"
            size="large"
            full
            loading={loading}
          >
            Save Changes
          </ButtonStyled>
        </Form>
      </div>
    </Card>
  );
};

export default Security;
