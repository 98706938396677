import React, { useState } from "react";
import { Drawer, DrawerProps } from "antd";
import { format } from "date-fns";
import { ReactComponent as CloseIcon } from "assets/svgs/close-icon.svg";
import { ReactComponent as AngleRight } from "assets/svgs/angle-right.svg";
import { ReactComponent as AngleLeft } from "assets/svgs/angle-left.svg";
import CustomCalendar from "../customCalendar";
import Heading from "../heading";
import styled from "styled-components";
import ButtonStyled from "../buttonStyled";
import { BayAreaProps, ShippingLineProps } from "api/booking";

type DateDrawerProps = DrawerProps & {
  saveDateAndSpace: (date: string, space: number) => void;
  shippingLine?: ShippingLineProps;
  selectedBayArea?: BayAreaProps;
};

const DateDrawer = ({
  visible,
  onClose,
  saveDateAndSpace,
  shippingLine,
  selectedBayArea,
}: DateDrawerProps) => {
  const [value, onChange] = useState(new Date());
  const [dateValue, setDateValue] = useState<Date | null>(null);

  const DrawerHeader = (
    <StyledHeader>
      <div>
        <p className="dropoff-zone">{selectedBayArea?.name}</p>
        <span className="shipping-line">{shippingLine?.name}</span>
      </div>

      <button onClick={onClose} className="close-btn">
        <CloseIcon />
      </button>
    </StyledHeader>
  );

  const handleDateChange = (date: Date) => {
    console.log(format(date, "yyyy-MM-dd"));

    onChange(date);
  };

  return (
    <Drawer
      placement="right"
      width={600}
      closable={false}
      onClose={onClose}
      visible={visible}
    >
      {DrawerHeader}

      <DrawerWrapper>
        <Heading as="H3" className="select-date-title">
          {!dateValue
            ? "Select date"
            : format(new Date(dateValue), "yyyy-MM-dd")}
        </Heading>

        {!dateValue ? (
          <>
            <CustomCalendar
              className="custom-calendar"
              onChange={(val: Date) => handleDateChange(val)}
              value={value}
              nextLabel={<AngleLeft />}
              next2Label={null}
              prevLabel={<AngleRight />}
              prev2Label={null}
            />

            <ButtonStyled
              className="btn-select-date"
              type="primary"
              onClick={() => setDateValue(value)}
            >
              Select
            </ButtonStyled>
          </>
        ) : (
          <SpaceAvailable>
            <div className="content-wrapper">
              <p className="space">{selectedBayArea?.available_space}</p>
              <span>Number of space available, based on the date seleted</span>
            </div>
            <div className="btn-wrapper">
              <ButtonStyled
                type="primary"
                ghost
                onClick={() => setDateValue(null)}
              >
                Edit Date
              </ButtonStyled>
              <ButtonStyled
                type="primary"
                onClick={() =>
                  saveDateAndSpace(
                    format(new Date(dateValue), "yyyy-MM-dd"),
                    237
                  )
                }
              >
                Continue
              </ButtonStyled>
            </div>
          </SpaceAvailable>
        )}
      </DrawerWrapper>
    </Drawer>
  );
};

export default DateDrawer;

const DrawerWrapper = styled.div`
  .select-date-title {
    padding: 20px 0;
    margin-bottom: 0;
    border-bottom: 1px solid #dee2e6;
  }

  .custom-calendar {
    margin-bottom: 2rem;
  }

  .btn-select-date {
    width: 150px;
  }
`;

const SpaceAvailable = styled.div`
  .content-wrapper {
    text-align: center;
    max-width: 250px;
    margin: 1.5rem auto 3.5rem;

    .space {
      font-size: 3.75rem;
      margin-bottom: 0;
      color: #3879f8;
    }
  }

  .btn-wrapper {
    display: flex;
    justify-content: center;
    gap: 2rem;

    button {
      width: 150px;
    }
  }
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1px solid #dee2e6;

  .close-btn {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
  }

  .dropoff-zone {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
  }

  .shipping-line {
    font-size: 12px;
  }
`;
