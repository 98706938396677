import React, { FC, ReactNode } from "react";
import { Select, SelectProps } from "antd";
import styled from "styled-components";

type CustomSelectProps = SelectProps & {
  prefixIcon?: ReactNode;
};

const CustomSelect: FC<CustomSelectProps> = ({
  prefixIcon,
  children,
  ...rest
}) => {
  if (prefixIcon) {
    return (
      <SelectWrapper>
        <div className="prefix-icon-wrapper">{prefixIcon}</div>

        <Select {...rest}>{children}</Select>
      </SelectWrapper>
    );
  }

  return <Select {...rest}>{children}</Select>;
};

export default CustomSelect;

const SelectWrapper = styled.div`
  position: relative;

  .prefix-icon-wrapper {
    position: absolute;
    z-index: 1;
    width: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-select .ant-select-selector {
    padding-left: calc(3rem - 8px);
  }
`;
