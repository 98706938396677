import React, { useEffect, useState } from "react";
import { Form, Checkbox } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "assets/img/Logo.png";
import InputStyled from "components/common/inputStyled";
import ButtonStyled from "components/common/buttonStyled";
import styled from "styled-components";
import api from "api";
import { useAuthContext } from "context/auth";

const FormItem = Form.Item;

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const { isAuthenticated, userInfo, dispatch } = useAuthContext();
  const navigate = useNavigate();
  const { state } = useLocation() as { state: any };

  useEffect(() => {
    if (isAuthenticated && userInfo) {
      if (state && state.from) {
        navigate(state.from);
      } else {
        navigate("/drop-off");
      }
    }
  }, [isAuthenticated, userInfo, state, navigate]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values = (await form.validateFields()) as {
        email: string;
        password: string;
      };

      const response = await api.auth.login(values);

      setLoading(false);

      if (response.status === 200) {
        dispatch({
          type: "USER_LOGIN",
          payload: response.data.data,
        });

        localStorage.setItem("userInfo", JSON.stringify(response.data.data));

        navigate("/drop-off");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Section>
      <LoginWrapper>
        <div className="logo-wrapper">
          <img src={Logo} alt="Logo" />
        </div>

        <Form
          name="signin"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <FormItem
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not a valid E-mail!",
              },
              {
                message: "Please input your Email!",
                required: true,
              },
            ]}
          >
            <InputStyled placeholder="Email Address" />
          </FormItem>

          <FormItem
            label="Password"
            name="password"
            rules={[{ message: "Please input your password", required: true }]}
          >
            <InputStyled.Password placeholder="Password" />
          </FormItem>

          <OptionsWrapper>
            <div className="remember-me">
              <Checkbox>Remember Me</Checkbox>
            </div>

            <span>
              <Link to="/forgot-password">Forgot your password?</Link>
            </span>
          </OptionsWrapper>

          <FormItem className="btn-signin-wrapper">
            <ButtonStyled
              className="btn-signin"
              htmlType="submit"
              type="primary"
              size="large"
              full
              loading={loading}
            >
              Sign In
            </ButtonStyled>
          </FormItem>

          <CreateAccountWrapper>
            No account yet? <Link to="/create-account">Create an Account</Link>
          </CreateAccountWrapper>
        </Form>
      </LoginWrapper>
    </Section>
  );
};

export default Login;

const Section = styled.section`
  min-height: 100vh;
  padding-top: 5rem;
  background: linear-gradient(
      180deg,
      rgba(56, 121, 248, 0) 33.33%,
      #3879f8 100%
    ),
    url("/assets/img/LoginBg.png");
  background-size: cover;
  background-position: center;
`;

const LoginWrapper = styled.div`
  max-width: 500px;
  margin: auto;
  background-color: var(--bg-white);
  padding: 4rem 4rem 3rem;
  border-radius: 8px;

  .logo-wrapper {
    text-align: center;
    margin-bottom: 3rem;
  }
`;

const OptionsWrapper = styled.div`
  margin-top: -12px;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: ${({ theme }) => theme["font-size-sm"]};
  }
`;

const CreateAccountWrapper = styled.div`
  margin-top: -12px;
  text-align: center;
`;
