import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    :root {
        --bg-white: #fff;
    }

    .table-responsive .ant-table-content {
        display: block;
        width: 100%;
        overflow-x: auto;
    }
`;

export default GlobalStyle;
