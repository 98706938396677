import React from "react";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import Heading from "../heading";
import ButtonStyled from "../buttonStyled";
import AddToCartButton from "../addToCartButton";
import { ReactComponent as CloseIcon } from "assets/svgs/close-icon.svg";
import { ReactComponent as SuccessIcon } from "assets/svgs/success-icon.svg";
import { ButtonWrapper, StyledHeader, SuccessWrapper } from "./styles";

const SuccessContent = ({
  onClose,
  reset,
}: {
  onClose: () => void;
  reset: () => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <StyledHeader borderless>
        <span></span>
        <button onClick={onClose} className="close-btn">
          <CloseIcon />
        </button>
      </StyledHeader>

      <SuccessWrapper>
        <SuccessIcon className="success-icon" />

        <Heading as="H2">Success!</Heading>

        <p>You have succesfully added the item to cart</p>
      </SuccessWrapper>

      <ButtonWrapper>
        <Link
          to="/drop-off"
          state={{ state: { isSameContainer: true, from: location.pathname } }}
          onClick={(e) => {
            e.preventDefault();

            navigate("/drop-off", {
              state: {
                isSameContainer: true,
                from: location.pathname,
              },
            });

            onClose();
            reset();
          }}
        >
          <AddToCartButton />
        </Link>

        <ButtonStyled
          type="primary"
          className="view-cart-btn"
          onClick={() => navigate("/cart")}
        >
          View Cart
        </ButtonStyled>
      </ButtonWrapper>
    </>
  );
};

export default SuccessContent;
