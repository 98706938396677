import { AxiosInstance, AxiosResponse } from "axios";

export type BookingProps = {
  drop_off: string;
  shipping_company: string;
  bay_area: string;
  status: string;
  date: string;
  laden_number: string;
  container_size: string;
  container_number: string;
};

export type ShippingLineProps = {
  id: string;
  name: string;
  date_added: string;
};

export type DemurageProps = {
  start_date: string;
  end_date: string;
  shipping_company: string;
  size: string;
};

export type BayAreaProps = {
  id: string;
  name: string;
  shipping_company: string;
  shipping_company_detail: {
    name: string;
  };
  address: string;
  available_space: number;
  date_added: string;
};

class Booking {
  client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  getAllBookings(): Promise<AxiosResponse<{ data: any }>> {
    return this.client.get("/bookings");
  }

  getShippingCompanies(): Promise<
    AxiosResponse<{ data: ShippingLineProps[] }>
  > {
    return this.client.get("/main/shipping_companies");
  }

  getDemurageSizes() {
    return this.client.get("/demurage/sizes/");
  }

  getDemurageSizesByShippingCompany(shippingLineId: string) {
    return this.client.get(
      `/demurage/sizes?shipping_company=${shippingLineId}`
    );
  }

  calculateDemurage(body: DemurageProps) {
    return this.client.post("/demurage/calculator/", body);
  }

  getMyBookings(): Promise<AxiosResponse<{ data: any }>> {
    return this.client.get("/bookings/mine");
  }

  getBayAreas(): Promise<AxiosResponse<{ data: BayAreaProps[] }>> {
    return this.client.get("/main/bay_area");
  }

  getSingleBooking(id: string): Promise<AxiosResponse<{ data: any }>> {
    return this.client.get(`/bookings/${id}`);
  }

  createBooking(body: any) {
    return this.client.post("/bookings/new/", body);
  }

  updateBooking(id: string): Promise<AxiosResponse<{ data: any }>> {
    return this.client.get(`/bookings/${id}`);
  }

  deleteBooking(id: string): Promise<AxiosResponse<{ data: any }>> {
    return this.client.delete(`/bookings/${id}`);
  }
}

export default Booking;
