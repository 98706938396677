import React from "react";
import { Spin } from "antd";
import styled from "styled-components";

const Wrapper = styled.div`
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = () => {
  return (
    <Wrapper>
      <Spin />
    </Wrapper>
  );
};

export default LoadingSpinner;
