import React, { useEffect, useState } from "react";
import { Section } from "pages/dashboard/styles";
import Heading from "components/common/heading";
import LoadingSpinner from "components/common/spinner";
import { useParams } from "react-router-dom";
import api from "api";
import { Modal } from "antd";

const ActivateAccount = () => {
  const [isActivating, setIsActivating] = useState(true);
  const [isActivated, setIsActivated] = useState(false);

  const params = useParams();

  const handleAccountActivation = async () => {
    try {
      const { uid, token } = params as { token: string; uid: string };

      const response = await api.auth.activateAccount({ token, uid });

      setIsActivating(false);

      if (response.status >= 200) {
        Modal.success({
          title: response.data.message,
        });

        setIsActivated(true);
      }
    } catch (error) {
      console.log(error);
      setIsActivating(false);
    }
  };

  useEffect(() => {
    handleAccountActivation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Section>
      <div className="max-width-wrapper">
        <Heading className="title">Activate Account</Heading>

        {isActivating && <LoadingSpinner />}

        {isActivated && <p>Account Verified</p>}
      </div>
    </Section>
  );
};

export default ActivateAccount;
