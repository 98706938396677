import React, { FC, Fragment, useRef, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { Badge } from "antd";
import Logo from "assets/img/Logo.png";
import { ReactComponent as FooterImg } from "assets/svgs/footer-img.svg";
import { ReactComponent as Hamburger } from "assets/svgs/menu.svg";

import useToggle from "../hooks/useToggle";
import {
  Header,
  Nav,
  AccountButton,
  AuthOptions,
  NavItems,
  DropdownItemStyled,
  Main,
  Footer,
  NavItemWrapper,
  StyledToggleButton,
  ImagePlaceholder,
  Div,
} from "./styles";
import { useAuthContext } from "context/auth";
import useOnClickOutside from "components/hooks/useOnClickOutside";
import { useCartContext } from "context/cart";
import { IUserProps } from "api/auth";
import SidebarDrawer from "./sidebarDrawer";

const DropdownItem = ({
  isOpen,
  toggleDropdown,
  handleLogout,
  closeDropdown,
  userInfo,
}: {
  isOpen: boolean;
  toggleDropdown: () => void;
  handleLogout: () => void;
  closeDropdown: () => void;
  userInfo: IUserProps;
}) => {
  const ref = useRef<any>();
  const handler = useCallback(() => closeDropdown(), [closeDropdown]);

  useOnClickOutside(ref, handler);

  return (
    <DropdownItemStyled ref={ref}>
      <AccountButton onClick={toggleDropdown}>
        <ImagePlaceholder />
        {userInfo?.first_name}
      </AccountButton>

      {isOpen && (
        <ul className="content">
          <Link to="/account-settings" onClick={closeDropdown}>
            <li>Account Settings</li>
          </Link>

          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();

              handleLogout();
            }}
          >
            <li>Log Out</li>
          </Link>
        </ul>
      )}
    </DropdownItemStyled>
  );
};

const ToggleButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <StyledToggleButton onClick={onClick}>
      <Hamburger />
    </StyledToggleButton>
  );
};

const Layout: FC = ({ children }) => {
  const [isDropdownOpen, toggleDropdown, closeDropdown] = useToggle(false);
  const { isAuthenticated, dispatch, userInfo } = useAuthContext();
  const location = useLocation();

  const [isSidebarOpen, toggleSidebar, closeSidebar] = useToggle(false);

  const {
    state: { containers },
  } = useCartContext();

  const handleLogOut = () => {
    dispatch({ type: "USER_LOGOUT", payload: null });

    localStorage.removeItem("userInfo");
  };

  return (
    <Fragment>
      <Header>
        <Nav>
          <div className="logo-wrapper">
            <Link to="/drop-off">
              <img src={Logo} alt="Logo" />
            </Link>
          </div>

          <NavItemWrapper>
            <NavItems>
              {/* <li>
                <Link to="/drop-off">Dashboard</Link>
              </li> */}
              <li>
                <Link
                  className={location.pathname === "/drop-off" ? "active" : ""}
                  to="/drop-off"
                >
                  Dashboard
                </Link>
              </li>

              <li>
                <a
                  href="http://calculator.mykontainer.app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Demurrage Calculator
                </a>
              </li>

              {/* <li>
                <a
                  className={location.pathname === "/about" ? "active" : ""}
                  href="https://mykontainer.app"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  About Us
                </a>
              </li> */}
              {/* <li>
                <Link
                  className={
                    location.pathname === "/calculate-demurrage" ? "active" : ""
                  }
                  to="/calculate-demurrage"
                >
                  Demurrage Calculator
                </Link>
              </li> */}
              {/* <li>
                <Link
                  className={location.pathname === "/contact" ? "active" : ""}
                  to="/contact"
                >
                  Contact Us
                </Link>
              </li> */}
            </NavItems>

            <AuthOptions>
              {!isAuthenticated && (
                <li>
                  <Link to="/create-account">Sign Up</Link>
                </li>
              )}

              <li>
                <Badge count={containers.length} color="#3879F8">
                  <Link
                    className={location.pathname === "/cart" ? "active" : ""}
                    to="/cart"
                  >
                    {/* <CartButton>Cart</CartButton> */}
                    Cart
                  </Link>
                </Badge>
              </li>

              <DropdownItem
                handleLogout={handleLogOut}
                isOpen={isDropdownOpen}
                toggleDropdown={toggleDropdown}
                closeDropdown={closeDropdown}
                userInfo={userInfo}
              />
            </AuthOptions>
          </NavItemWrapper>

          <ToggleButton onClick={toggleSidebar} />
        </Nav>
      </Header>

      {isSidebarOpen ? (
        <SidebarDrawer visible={isSidebarOpen} onClose={closeSidebar} />
      ) : null}

      <Main>
        {children}

        {location.pathname !== "/account-settings" && (
          <div>
            <FooterImg className="footer-img" />
          </div>
        )}
      </Main>

      {location.pathname !== "/account-settings" && (
        <Footer borderless={location.pathname === "/account-settings"}>
          {location.pathname === "/calculate-demurrage" && (
            <div className="content-wrapper">
              <header>
                <strong>Demurrage Calculator Disclaimer</strong>
              </header>

              <p>
                These demurrage and detention charges are not an offer and have
                no legal effect. They are estimates only, that may apply to your
                shipment based on the inputs you provided above, thus, the
                results may not reflect the amounts actually billed. This
                estimate is calculated according to the method of demurrage and
                detention billing applied by each shipping company. In case of a
                discrepancy between the results presented by this calculator and
                the actual charges billed in the invoice issued to you, the
                amounts and information specified on the invoice shall prevail.
                Please use this calculator at you own discretion. MyKontainer
                Technologies is not responsible for any decision or actions
                taken in reliance upon or as a result of the results provided by
                this calculator.
              </p>
            </div>
          )}
        </Footer>
      )}

      <Div bg="#f5f5f5">
        <Nav>
          <Link to="/drop-off">
            <img src={Logo} alt="Logo" />
          </Link>

          <NavItemWrapper>
            <NavItems>
              <li>
                <a
                  className={location.pathname === "/about" ? "active" : ""}
                  href="https://mykontainer.app/#about"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  About Us
                </a>
              </li>

              <li>
                <Link
                  className={location.pathname === "/contact" ? "active" : ""}
                  to="/contact"
                >
                  Contact Us
                </Link>
              </li>
            </NavItems>
          </NavItemWrapper>
        </Nav>
      </Div>
    </Fragment>
  );
};

export default Layout;
