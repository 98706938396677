import React, { useState } from "react";
import Heading from "components/common/heading";
import { Main, Section, Sidebar } from "./styles";
import Settings from "./settings";
import Security from "./security";
import Activity from "./activity";
import { useAuthContext } from "context/auth";

const AccountSettings = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const { userInfo } = useAuthContext();

  const tabData = [
    {
      label: "Settings",
      content: <Settings />,
    },

    // {
    //   label: "Notifications",
    //   content: <Notifications />,
    // },
    {
      label: "Activity",
      content: <Activity />,
    },
    {
      label: "Security",
      content: <Security />,
    },
  ];

  return (
    <Section>
      <Heading className="main-header">
        Welcome Back! {userInfo.first_name}
      </Heading>

      <Main>
        <Sidebar>
          <ul>
            {tabData.map((tab, idx) => (
              <li key={tab.label} onClick={() => setCurrentPage(idx)}>
                {tab.label}
              </li>
            ))}
          </ul>
        </Sidebar>

        {tabData[currentPage].content}
      </Main>
    </Section>
  );
};

export default AccountSettings;
