import React from "react";
import theme from "./theme";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./globalStyle";
import AppRoutes from "./routes";
import CartProvider from "context/cart";
import BookingProvider from "context/booking";
import "./App.less";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BookingProvider>
        <CartProvider>
          <GlobalStyle />
          <AppRoutes />
        </CartProvider>
      </BookingProvider>
    </ThemeProvider>
  );
};

export default App;
