import React from "react";
import { ReactComponent as AddIcon } from "assets/svgs/add-icon.svg";
import styled from "styled-components";

const AddToCartButton = () => {
  return (
    <StyledButton className="add-to-cart-btn">
      <span>Add New Container</span>

      <AddIcon />
    </StyledButton>
  );
};

export default AddToCartButton;

const StyledButton = styled.button`
  width: fit-content;
  height: 60px;
  background: #f5f6f8;
  border: none;
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  color: #000;
  cursor: pointer;

  span {
    flex-shrink: 0;
  }

  svg {
    flex-shrink: 0;
    margin-left: 10px;
  }
`;
