import React, { useState, useEffect } from "react";
import { Col, Form, message, Row, Select, Tag } from "antd";
import Heading from "components/common/heading";
import { ReactComponent as LocationIcon } from "assets/svgs/location.svg";
import { ReactComponent as CalendarIcon } from "assets/svgs/calendar.svg";
import { ReactComponent as UnicornIcon } from "assets/svgs/unicorn.svg";
import { ReactComponent as SelectDropdownIcon } from "assets/svgs/select-dropdown.svg";
import CustomSelectDropdown from "components/common/customSelectDropdown";
import CustomSelect from "components/common/customSelect";
import ButtonStyled from "components/common/buttonStyled";
import useToggle from "components/hooks/useToggle";
import DateDrawer from "components/common/dateDrawer";
import InputStyled from "components/common/inputStyled";
import SummaryDrawer from "components/common/summaryDrawer";
import { DateButton, Section } from "./styles";
import { useBookingContext } from "context/booking";
import { BayAreaProps, ShippingLineProps } from "api/booking";
import { useLocation } from "react-router-dom";
import { useCartContext } from "context/cart";
import LoadingSpinner from "components/common/spinner";

const FormItem = Form.Item;
const Option = Select.Option;

const Dashboard = () => {
  const [availability, setAvailability] = useState({
    date: "",
    space: 0,
  });
  const [shippingLine, setShippingLine] = useState<
    ShippingLineProps | undefined
  >();
  const [bayAreas, setBayAreas] = useState<BayAreaProps[]>([]);
  const [selectedBayArea, setSelectedBayArea] = useState<BayAreaProps>();

  const [isOpen, toggleDateDrawer] = useToggle(false);
  const [isSummaryDrawerOpen, toggleSummaryDrawer] = useToggle(false);

  const { isLoading, shippingData, bayAreaData } = useBookingContext();
  const {
    state: { containers, summaryDetails },
    dispatch,
  } = useCartContext();

  const [form] = Form.useForm();

  const location = useLocation() as any;

  console.log({ isSameContainer: location?.state?.isSameContainer });

  // ===================================================== //
  useEffect(() => {
    if (
      location?.state?.isSameContainer &&
      containers.length > 0 &&
      !isLoading
    ) {
      const shippingLine = containers[0].shippingLine;
      const bayArea = containers[0].bay_area;

      const result = bayAreaData.filter(
        (bayArea) => bayArea.shipping_company === shippingLine.id
      );

      console.log("from useeffect", result);

      setBayAreas(result);

      if (shippingLine && bayArea) {
        setShippingLine(shippingLine);
        setSelectedBayArea(bayArea);
      }
    }
  }, [containers, location?.state?.isSameContainer, bayAreaData, isLoading]);
  // ===================================================== //

  const handleSubmit = async () => {
    const values = await form.validateFields();

    dispatch({
      type: "ADD_SUMMARY",
      payload: {
        ...values,
        date: availability.date,
        shippingLine,
        bay_area: selectedBayArea,
      },
    });

    toggleSummaryDrawer();
  };

  const handleSelectShippingLine = (shippingId: string) => {
    setSelectedBayArea(undefined);

    const selectedShippingLine = shippingData.find(
      (shippingLine) => shippingLine.id === shippingId
    );

    const selectedBayArea = bayAreaData.filter(
      (bayArea) => bayArea.shipping_company === shippingId
    );

    if (selectedBayArea) {
      console.log({ selectedBayArea });
      setShippingLine(selectedShippingLine);
      setBayAreas(selectedBayArea);
    } else {
      setShippingLine(selectedShippingLine);
      setBayAreas([]);
      message.warn("No bay areas in sselected shipping line.");
    }
  };

  const handleSelectBayArea = (bayAreaId: string) => {
    const selectedBayArea = bayAreaData.find(
      (bayArea) => bayArea.id === bayAreaId
    );

    if (selectedBayArea) {
      setSelectedBayArea(selectedBayArea);
    } else {
      setSelectedBayArea(undefined);
      // message.warn("No bay areas in sselected shipping line.");
    }
  };

  const reset = () => {
    setAvailability({
      date: "",
      space: 0,
    });

    setShippingLine(undefined);
    setSelectedBayArea(undefined);
    // setDropOffZone("");
  };

  const resetFields = () => {
    console.log("RESET has been called");

    form.setFieldsValue("laden_number");
    form.setFieldsValue("container_size");
    form.setFieldsValue("container_number");
  };

  const handleAvailability = (date: string, space: number) => {
    setAvailability({
      date,
      space,
    });

    toggleDateDrawer();
  };

  // console.log(location);

  return (
    <Section>
      <div className="max-width-wrapper">
        <Heading className="title">Drop off a container</Heading>

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Form
            name="dropoffContainer"
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            initialValues={{
              shipping_company: summaryDetails.shipping_company,
              bay_area: summaryDetails.bay_area?.id,
            }}
          >
            <FormItem
              label="Select Shipping line"
              name="shipping_company"
              rules={[
                {
                  message: "Please select shipping line",
                  required: true,
                },
              ]}
            >
              <CustomSelect
                loading={isLoading}
                prefixIcon={<LocationIcon />}
                suffixIcon={<SelectDropdownIcon />}
                dropdownRender={(menu) => <CustomSelectDropdown menu={menu} />}
                dropdownClassName="custom-dropdown-class"
                onChange={(val) => handleSelectShippingLine(val)}
                disabled={location?.state?.isSameContainer}
              >
                {shippingData.map((shippingLine) => (
                  <Option key={shippingLine.id} value={shippingLine.id}>
                    {shippingLine.name}
                  </Option>
                ))}
              </CustomSelect>
            </FormItem>

            {bayAreas.length > 0 ? (
              <FormItem
                label="Select Dropoff Zone"
                name="bay_area"
                rules={[
                  {
                    message: "Please select dropoff zone",
                    required: true,
                  },
                ]}
              >
                <CustomSelect
                  loading={isLoading}
                  prefixIcon={<LocationIcon />}
                  suffixIcon={<SelectDropdownIcon />}
                  dropdownRender={(menu) => (
                    <CustomSelectDropdown menu={menu} />
                  )}
                  dropdownClassName="custom-dropdown-class"
                  onChange={(val) => handleSelectBayArea(val)}
                  disabled={location?.state?.isSameContainer}
                >
                  {bayAreaData.map((bayArea) => (
                    <Option key={bayArea.id} value={bayArea.id}>
                      {bayArea.name}
                    </Option>
                  ))}
                </CustomSelect>
              </FormItem>
            ) : null}

            {selectedBayArea && (
              <FormItem
                label="Check available dates"
                name="date"
                // rules={[
                //   {
                //     message: "Please select any available dates",
                //     required: true,
                //   },
                // ]}
              >
                <DateButton
                  onClick={toggleDateDrawer}
                  icon={<CalendarIcon />}
                  type="default"
                  suffixIcon={
                    availability.space ? (
                      <Tag
                        color="green"
                        children={selectedBayArea.available_space}
                      />
                    ) : null
                  }
                  full
                >
                  {availability.date || "Select Date"}
                </DateButton>
              </FormItem>
            )}

            {availability.date && (
              <>
                <Row gutter={24}>
                  <Col sm={24} md={12}>
                    <FormItem
                      label="Bill of laden number"
                      name="laden_number"
                      rules={[
                        {
                          message: "please enter laden number",
                          required: true,
                        },
                      ]}
                    >
                      <InputStyled
                        prefix={<UnicornIcon />}
                        placeholder="Enter Laden Number"
                      />
                    </FormItem>
                  </Col>

                  <Col sm={24} md={12}>
                    <FormItem
                      label="Container Number"
                      name="container_number"
                      rules={[
                        {
                          message: "please enter container number",
                          required: true,
                        },
                      ]}
                    >
                      <InputStyled
                        prefix={<UnicornIcon />}
                        placeholder="Enter Container Number"
                      />
                    </FormItem>
                  </Col>

                  <Col sm={24} md={24}>
                    <FormItem
                      label="Container Size"
                      name="container_size"
                      rules={[
                        {
                          message: "please select container size",
                          required: true,
                        },
                      ]}
                    >
                      <CustomSelect
                        prefixIcon={<UnicornIcon />}
                        dropdownRender={(menu) => (
                          <CustomSelectDropdown menu={menu} />
                        )}
                        dropdownClassName="custom-dropdown-class"
                      >
                        <Option value="20 feet">20 feet</Option>
                        <Option value="40 feet">40 feet</Option>
                      </CustomSelect>
                    </FormItem>
                  </Col>
                </Row>

                <FormItem>
                  <ButtonStyled
                    htmlType="submit"
                    className="view-summary-btn"
                    type="primary"
                  >
                    View Summary
                  </ButtonStyled>
                </FormItem>
              </>
            )}
          </Form>
        )}
      </div>

      {isOpen ? (
        <DateDrawer
          visible={isOpen}
          onClose={toggleDateDrawer}
          shippingLine={shippingLine}
          selectedBayArea={selectedBayArea}
          saveDateAndSpace={handleAvailability}
        />
      ) : null}

      {isSummaryDrawerOpen ? (
        <SummaryDrawer
          visible={isSummaryDrawerOpen}
          onClose={toggleSummaryDrawer}
          reset={reset}
          resetFields={resetFields}
        />
      ) : null}
    </Section>
  );
};

export default Dashboard;
