import styled from "styled-components";

const H2 = styled.h2`
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
`;

const H3 = styled.h3`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
`;

export { H2, H3 };
