import React, { FC } from "react";
import styled from "styled-components";

const CustomError: FC = (props) => {
  return <StyledError>{props.children}</StyledError>;
};

export default CustomError;

const StyledError = styled.ul`
  margin-top: 2rem;
  border-radius: 0.25rem;
  width: 100%;
  padding: 1rem;
  padding-left: 2rem;
  color: rgba(155, 28, 28, 1);
  background-color: rgba(251, 213, 213, 1);

  .text-sm {
    font-size: 0.875rem;
  }
`;
