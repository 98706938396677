import { Input } from "antd";
import styled from "styled-components";

const InputStyled = styled(Input)`
  border-radius: 6px;

  textarea.ant-input {
    height: 150px;
  }

  /* &.ant-input::placeholder,
  &.ant-select-selection-placeholder {
    color: ${({ theme }) => theme["text-placeholder"]};
  }

  & span.ant-select-selection-placeholder {
    color: ${({ theme }) => theme["text-placeholder"]};
  } */
  /* 
  &.ant-input,
  #login_password,
  &.ant-input-affix-wrapper > input.ant-input {
    background-color: #f9f9f9;
  } */
`;

export default InputStyled;
