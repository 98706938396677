import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Layout from "../components/layout";
import CreateAccount from "../pages/createAccount";
import Dashboard from "../pages/dashboard";
import Login from "../pages/login";
import Cart from "pages/cart";
import About from "pages/about";
import FAQ from "pages/faq";
import DemurrageCalculator from "pages/demurrageCalculator";
import Contact from "pages/contact";
import AccountSettings from "pages/accountSettings";
import { useAuthContext } from "context/auth";
import VerifyAccount from "pages/verifyAccount";
import CVerifyAccount from "pages/cVerifyAccount";
import ForgotPassword from "pages/forgotPassword";
import ConfirmPassword from "pages/confirmPassword";
import TermsAndConditions from "pages/termsAndConditions";

const NotFound = () => <h2>Page Not Found</h2>;

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="login" element={<Login />} />
      <Route path="terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password/:token" element={<ConfirmPassword />} />
      <Route path="verify-account" element={<VerifyAccount />} />
      <Route path="activate-account/:uid/:token" element={<CVerifyAccount />} />
      <Route path="create-account" element={<CreateAccount />} />
      <Route
        path="drop-off"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      />
      <Route
        path="cart"
        element={
          <RequireAuth>
            <Cart />
          </RequireAuth>
        }
      />
      {/* <Route
        path="calculate-demurrage"
        element={
          <RequireAuth>
            <DemurrageCalculator />
          </RequireAuth>
        }
      /> */}
      <Route
        path="about"
        element={
          <RequireAuth>
            <About />
          </RequireAuth>
        }
      />
      <Route
        path="contact"
        element={
          <RequireAuth>
            <Contact />
          </RequireAuth>
        }
      />
      <Route
        path="faq"
        element={
          <RequireAuth>
            <FAQ />
          </RequireAuth>
        }
      />
      <Route
        path="account-settings"
        element={
          <RequireAuth>
            <AccountSettings />
          </RequireAuth>
        }
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  let context = useAuthContext();
  let location = useLocation();

  if (!context.isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  return <Layout>{children}</Layout>;
};
