import { Collapse } from "antd";
import styled from "styled-components";

const CollapseStyled = styled(Collapse)`
  border-radius: 6px;
  background: #fff;

  .ant-collapse-item {
    .ant-collapse-header {
      padding: 1.5rem 2rem;
      border-radius: 0 0 6px 6px;
    }

    .ant-collapse-content {
      .ant-collapse-content-box {
        .cart-item:last-child {
          border-bottom: 0;
        }
      }
    }
  }

  .ant-collapse-content-box {
    padding: 0 2rem;
  }

  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0 0 6px 6px;
  }
`;

export default CollapseStyled;
