import axios from "axios";
import { message as Toast } from "antd";
import Auth, { IUserProps } from "./auth";
import Booking from "./booking";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 500000,
});

// for multiple requests
let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Instantiate the interceptor
// createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

// Add a request interceptor
axiosInstance.interceptors.request.use(function (config) {
  // Do something before request is sent
  let userInfo;
  if (localStorage.getItem("userInfo")) {
    userInfo = JSON.parse(localStorage.getItem("userInfo")!);
  }

  return {
    ...config,
    headers: {
      authorization: userInfo ? `Bearer ${userInfo.access}` : null,
    },
  };
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    console.log(error.response);

    // Do something with response error
    const errorResponse = error.response;
    const originalConfig = error?.config;

    if (errorResponse.config.url === "/auth/users/") {
      const error: string[] = Object.values(errorResponse.data);

      Toast.error(error[0]?.toString());

      return Promise.reject(error[0]);
    }

    if (
      errorResponse.config.url === "/user/add_delivery_person/" ||
      errorResponse.config.url === "/user/add_admin/"
    ) {
      const errMsg = errorResponse.data.error.phone[0];

      Toast.error(errMsg);

      return Promise.reject(error);
    }

    if (errorResponse.config.url === "/user/add_admin/") {
      const errMsg = errorResponse.data.error.email[0];

      Toast.error(errMsg);

      return Promise.reject(error);
    }

    if (
      errorResponse.config.url === "/auth/users/reset_password_confirm/" ||
      errorResponse.config.url === "/auth/users/reset_password/"
    ) {
      return Promise.reject(error);
    }

    if (
      errorResponse.config.url === "/auth/jwt/refresh/" &&
      (errorResponse.data.code === "token_not_valid" ||
        errorResponse.data.detail === "Token is invalid or expired")
    ) {
      localStorage.removeItem("userInfo");

      window.location.href = "/login";

      console.log("Session has expired. Please login again.");

      Toast.error("Session has expired. Please login again.");
    }

    if (
      window.location.pathname !== "/login" &&
      errorResponse.status === 401 &&
      errorResponse.data.detail !== "Token is blacklisted" &&
      !originalConfig._retry
    ) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalConfig.headers["authorization"] = "Bearer " + token;
            return axiosInstance(originalConfig);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalConfig._retry = true;
      isRefreshing = true;

      let userInfo: any = {};
      if (localStorage.getItem("userInfo")) {
        userInfo = JSON.parse(localStorage.getItem("userInfo")!) as IUserProps;
      }

      const refreshToken = userInfo?.refresh;

      return new Promise(function (resolve, reject) {
        axiosInstance
          .post("/auth/jwt/refresh/", { refresh: refreshToken })
          .then((res) => {
            const { access, refresh } = res.data;

            localStorage.setItem(
              "userInfo",
              JSON.stringify({ ...userInfo!, access, refresh })
            );

            originalConfig.headers["authorization"] = `Bearer ${access}`;

            processQueue(null, access);
            resolve(axiosInstance(originalConfig));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    let errorMsg;

    const errorMessage =
      typeof errorResponse.data.error === "string"
        ? errorResponse.data.error
        : errorResponse.data.errors &&
          errorResponse.data.errors.length &&
          errorResponse.data.errors[0]
        ? errorResponse.data.errors.toString()
        : typeof errorResponse.data.error === "object"
        ? Object.entries(errorResponse.data.error)[0][1]
        : errorResponse.data.message;

    const detail =
      errorResponse && errorResponse.data.detail
        ? errorResponse.data.detail
        : errorResponse.detail;

    const statusText = errorResponse && errorResponse.statusText;

    if (errorMessage) {
      errorMsg = errorMessage;
    } else if (detail) {
      errorMsg = detail;
    } else {
      errorMsg = statusText;
    }

    Toast.error(errorMsg);

    return Promise.reject(error);
  }
);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  auth: new Auth(axiosInstance),
  booking: new Booking(axiosInstance),
  HttpClient: axiosInstance,
};
