import React from "react";
import Heading from "components/common/heading";
import { Section } from "pages/dashboard/styles";

const FAQ = () => {
  return (
    <Section>
      <div className="max-width-wrapper">
        <Heading className="title">FAQ</Heading>
      </div>
    </Section>
  );
};

export default FAQ;
