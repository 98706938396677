import React from "react";
import { Form, Row, Select, Col } from "antd";
import Heading from "components/common/heading";
import { Section } from "pages/dashboard/styles";
import CustomSelect from "components/common/customSelect";
import CustomSelectDropdown from "components/common/customSelectDropdown";
import { ReactComponent as UserIcon } from "assets/svgs/user.svg";
import { ReactComponent as SelectDropdownIcon } from "assets/svgs/select-dropdown.svg";
import InputStyled from "components/common/inputStyled";
import ButtonStyled from "components/common/buttonStyled";

const FormItem = Form.Item;
const Option = Select.Option;

const Contact = () => {
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    const values = await form.validateFields();

    console.log(values);

    //   toggleSummaryDrawer();
  };

  return (
    <Section>
      <div className="max-width-wrapper">
        <Heading className="title">Contact Us</Heading>

        <Form
          name="demurrageCalculator"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <FormItem label="Select a Reason" name="reason">
            <CustomSelect
              prefixIcon={<UserIcon />}
              suffixIcon={<SelectDropdownIcon />}
              dropdownRender={(menu) => <CustomSelectDropdown menu={menu} />}
              dropdownClassName="custom-dropdown-class"
            >
              <Option value="Customer Service">Customer Service</Option>
              <Option value="Transporter">Transporter</Option>
              <Option value="Clearing Agent">Clearing Agent</Option>
              <Option value="Other">Other</Option>
            </CustomSelect>
          </FormItem>

          <Row gutter={24}>
            <Col xs={24} sm={24} md={12}>
              <FormItem
                label="First Name"
                name="first_name"
                rules={[
                  {
                    message: "Please input your first name",
                    required: true,
                  },
                ]}
              >
                <InputStyled placeholder="First Name" />
              </FormItem>
            </Col>

            <Col xs={24} sm={24} md={12}>
              <FormItem
                label="Last Name"
                name="last_name"
                rules={[
                  {
                    message: "Please input your last name",
                    required: true,
                  },
                ]}
              >
                <InputStyled placeholder="Last Name" />
              </FormItem>
            </Col>
          </Row>

          <FormItem label="Business Name" name="company_name">
            <InputStyled placeholder="Company Name" />
          </FormItem>

          <FormItem
            label="Phone"
            name="phone"
            rules={[
              {
                message: "Please input your phone number !",
                required: true,
              },
            ]}
          >
            <InputStyled placeholder="Phone Number" />
          </FormItem>

          <FormItem
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not a valid E-mail!",
              },
              {
                message: "Please input your Email!",
                required: true,
              },
            ]}
          >
            <InputStyled placeholder="Email Address" />
          </FormItem>

          <FormItem
            label="Address"
            name="address"
            rules={[
              {
                message: "Please input your address",
                required: true,
              },
            ]}
          >
            <InputStyled placeholder="Address" />
          </FormItem>

          <FormItem label="Message / Remarks" name="remarks">
            <InputStyled.TextArea rows={4} />
          </FormItem>

          <FormItem>
            <ButtonStyled
              htmlType="submit"
              className="calculate-demurrage-btn"
              type="primary"
            >
              Send
            </ButtonStyled>
          </FormItem>
        </Form>
      </div>
    </Section>
  );
};

export default Contact;
