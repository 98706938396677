import Card from "components/common/card";
import styled from "styled-components";

const Section = styled.section`
  background-color: #f5f6f8;
  padding: 2rem 4rem;
  min-height: calc(100vh - 105px);

  .main-header {
    margin-bottom: 3rem;
  }
`;

const Main = styled.main`
  display: flex;

  .view {
    width: 50%;
    padding-bottom: 2rem;
    flex-grow: 1;
  }
  .btn-save {
    width: 204px;
  }
`;

const Sidebar = styled.aside`
  width: 300px;

  ul {
    margin-top: 1rem;
    padding-left: 0;
    list-style: none;

    li {
      padding: 0.5rem 0;
      cursor: pointer;

      :hover {
        color: ${({ theme }) => theme["primary-color"]};
      }
    }
  }
`;

const FormWrapper = styled.div`
  padding-top: 3.5rem;
  border-top: 1px solid #dadada;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 3rem;

  .checkbox-item:not(:last-child) {
    margin-right: 2rem;
    margin-left: 0;
  }
`;

const ActivityCardWrapper = styled.div`
  padding: 1.5rem;

  .title {
    margin-bottom: 3.5rem;

    h2 {
      font-weight: 500;
      font-size: 32px;
      line-height: 42px;
    }
  }

  .custom-collapse-item {
    margin-bottom: 1.5rem;
  }
`;

export {
  Section,
  Main,
  Sidebar,
  FormWrapper,
  CheckboxWrapper,
  ActivityCardWrapper,
};
