import React from "react";
import Heading from "components/common/heading";
import { Section } from "pages/dashboard/styles";

const About = () => {
  return (
    <Section>
      <div className="max-width-wrapper">
        <Heading className="title">About Us</Heading>

        <p>
          MyKontainer Technology Solutions’ mission is to enhance the
          operational efficiency in the container port and post port operations;
          this a critical area in the container shipping, freight and logistics
          industry.
        </p>

        {/* <p>
          MyKontainer is designed to enable supply chain stakeholders in the
          container logistics industry (consignees, clearing and forwarding
          agents, transport and logistics companies, holding bays operators and
          shipping companies) conduct their businesses smoothly and efficiently
          by reducing gridlock, improving visibility and providing relevant data
          for real time management and optimization of the supply chain.
        </p>

        <p>
          MyKontainer platform leverages technology to minimize the complexity
          of container movement process and save stakeholders time and money.
          The intuitive platform helps supply chain partners book appointments
          for container return at holding bays in a few simple steps as well as
          estimating demurrage and detention charges; all on one platform.
        </p> */}

        <p>
          The vision for MyKontainer is to be that platform for efficient
          container logistics management in Africa focusing on providing
          solutions to the major pain points such as empty handling, container
          freight stations, breakbulk consolidation and transfer, container
          inventory management system, information hub, data and market analysis
          and intelligence. Currently, we are prioritizing efficient shipping
          container return and drop off processes with total visibility and
          ease. Our team is here to ensure that these processes are seamless,
          painless and successful.
        </p>

        {/* <div className="mt-2">
          <h3>Problems in Container Logistics Space</h3>

          <ul>
            <li>
              Third parties have hijacked empty container return process and
              created levies to access various depots. This arbitrage is because
              container movement process is not being managed systematically and
              currently does not consider road access, daily space availability
              in the depots, optimizing under-utilized depots and real-time,
              all-time communication to supply chain stakeholders (truckers,
              agents and consignees).
            </li>

            <li>
              Difficulties in returning empty containers through an inefficient
              truck traffic management system, coupled with poor communication,
              and the high costs to return containers have influenced the theft
              and loss of shipping containers, especially in the last four
              years.{" "}
            </li>

            <li>
              Supply chain stakeholders are burdened with shouldering these
              costs of structural inefficiencies in container movement
              activities by having to pay huge sums as demurrage and detention
              charges.{" "}
            </li>

            <li>
              Calculation of demurrage and detention charges are currently
              complex and opaque. This is due to its progressive nature,
              different rate per size and equipment type, etc.
            </li>

            <li>
              Other partners in the container shipping, freight and logistics
              industry besides the shipping companies have not leveraged
              technology enough to improve the supply chain of container
              management.{" "}
            </li>
          </ul>
        </div> */}

        {/* <div className="mt-2">
          <h3>
            Our Solution: MyKontainer - Providing Ease, Visibility and
            Accountability
          </h3>

          <p>
            MyKontainer enables sustainable and efficient reusable asset control
            processes by providing ease of returning containers and visibility
            to the returning process at the holding bays while ensuring
            accountability at every level of the process. MyKontainer will
            alleviate the current pains that supply chain partners encounter by:
          </p>

          <ul>
            <li>
              Removing the influence of extortion with data-driven solution and
              excellence customer service that will provide information and
              support to truckers.
            </li>

            <li>
              Giving real time visibility on the utilization ratio of the
              holding bay and ability to accept more empty containers.
            </li>

            <li>
              Making it easy to track container drop offs and helping the
              shipping companies and container holding bay operators maintains
              accurate inventory status of containers at the holding bays.
            </li>

            <li>
              Providing an easy demurrage and detention calculation tool
              configured for all shipping companies for ease in reconciling
              account statements and enabling payment.
            </li>

            <li>
              Reducing demurrage and detention costs caused by uncertainty and
              lack of visibility and availability of holding bay assets.
            </li>
          </ul>
        </div> */}

        <div className="mt-2">
          <h3>Our Current Features & Offerings:</h3>

          <ul>
            <li>Container Drop-off Appointments Scheduling</li>
            <li>Demurrage & Detention Calculator</li>
            <li>Real time container traffic management system</li>
          </ul>
        </div>
      </div>
    </Section>
  );
};

export default About;
