import React from "react";
import { Modal, Space, Empty } from "antd";
import { ActivityCardWrapper } from "./styles";
import CollapseStyled from "components/common/collapseStyled";
import { ReactComponent as AddIcon } from "assets/svgs/add.svg";
import { ReactComponent as TimesIcon } from "assets/svgs/times.svg";
import { SummaryItem } from "components/common/summaryDrawer/styles";

const { Panel } = CollapseStyled;

type IActivityModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  currentDate: string;
  history: any[];
};

const ActivityModal = ({
  isOpen,
  closeModal,
  currentDate,
  history,
}: IActivityModalProps) => {
  return (
    <Modal
      visible={isOpen}
      onCancel={closeModal}
      title={null}
      footer={null}
      closable={false}
      width={1000}
    >
      <ActivityCardWrapper>
        <div className="title">
          <h2>{currentDate}</h2>
        </div>

        <div>
          {history.length > 0 ? (
            history?.map((item: any, idx) => (
              <CollapseStyled
                className="custom-collapse-item"
                defaultActiveKey={["1"]}
                expandIconPosition="right"
                expandIcon={(props) =>
                  props.isActive ? (
                    <TimesIcon {...props} />
                  ) : (
                    <AddIcon {...props} />
                  )
                }
                onChange={() => {}}
              >
                <Panel header={<strong>MK00{idx + 1}</strong>} key="1">
                  <SummaryItem>
                    <p>Shipping Line:</p>
                    <p>{item?.shipping_company_detail?.name}</p>
                  </SummaryItem>

                  <SummaryItem>
                    <p>Holding Bay:</p>
                    <p>{item?.bay_area_detail?.name}</p>
                  </SummaryItem>

                  <SummaryItem>
                    <p>Anticipated drop off date:</p>
                    <p>{item?.date}</p>
                  </SummaryItem>

                  <SummaryItem>
                    <p>Drop off Code:</p>
                    <p>{item?.drop_off}</p>
                  </SummaryItem>

                  <SummaryItem>
                    <p>Bill of Laden:</p>
                    <p>{item?.laden_number}</p>
                  </SummaryItem>

                  <SummaryItem>
                    <p>Container Number:</p>
                    <p>{item?.container_number}</p>
                  </SummaryItem>

                  <SummaryItem>
                    <p>Container Size:</p>
                    <p>{item?.container_size}</p>
                  </SummaryItem>
                </Panel>
              </CollapseStyled>
            ))
          ) : (
            <Empty />
          )}
        </div>
      </ActivityCardWrapper>
    </Modal>
  );
};

export default ActivityModal;
