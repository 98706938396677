import { Checkbox } from "antd";
import styled from "styled-components";

const CheckboxStyled = styled(Checkbox)`
  color: #1c4e63;

  .ant-checkbox .ant-checkbox-inner {
    width: 25px;
    height: 25px;
    margin-top: -7px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    width: 25px;
    height: 25px;
  }

  .ant-checkbox {
    margin: auto;
  }

 .ant-checkbox .ant-checkbox-inner::after {
  transform: scale(1.7) rotate(45deg);
  position: relative;
  top: 6px;
  left: 9px;
}
`;
export default CheckboxStyled;
