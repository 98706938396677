import { Upload } from "antd";
import ButtonStyled from "components/common/buttonStyled";
import styled from "styled-components";

const Section = styled.section`
  padding: 3rem 0 30rem;

  .mt-2 {
    margin-top: 2rem;
  }

  .success-table {
    max-width: 1024px;
    margin: 0 auto;

    .actions-wrapper {
      margin-top: 2rem;

      .actions {
        .ant-btn:first-child {
          margin-right: 1rem;
        }
      }
    }

    .close-success-table {
      margin-bottom: 1rem;

      .close-btn {
        background: none;
        border: 0;
        cursor: pointer;
        padding: 0;
      }
    }
  }

  .max-width-wrapper {
    max-width: 700px;
    margin: 0 auto;
    padding: 0 1.5rem;

    .title {
      text-align: center;
      margin-bottom: 3rem;
    }

    .view-summary-btn,
    .calculate-demurrage-btn {
      width: 200px;
      margin-top: 2rem;
    }
  }
`;

const DateButton = styled(ButtonStyled)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8rem;
`;

const UploadStyled = styled(Upload)`
  display: block;

  .ant-upload {
    width: 100%;

    .upload-btn {
      background: #f5f6f8;
      border: 1px solid #dbdee7;
    }
  }
`;

export { Section, DateButton, UploadStyled };
