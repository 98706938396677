import React, { useState } from "react";
import { Empty, Modal } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddToCartButton from "components/common/addToCartButton";
import Heading from "components/common/heading";
import { SummaryItem } from "components/common/summaryDrawer/styles";
import { ReactComponent as AddIcon } from "assets/svgs/add.svg";
import { ReactComponent as TimesIcon } from "assets/svgs/times.svg";
import { ReactComponent as TrashIcon } from "assets/svgs/trash.svg";
import ButtonStyled from "components/common/buttonStyled";
import CollapseStyled from "components/common/collapseStyled";
import { CollapseWrapper, DeleteCartItemBtn, Section } from "./styles";
import styled from "styled-components";
import { useCartContext } from "context/cart";
import api from "api";

const { Panel } = CollapseStyled;

const Cart = () => {
  const [loading, setLoading] = useState(false);

  const {
    state: { containers },
    dispatch,
    removeItemFromCart,
  } = useCartContext();

  const location = useLocation();
  const navigate = useNavigate();

  const handleBooking = async () => {
    try {
      const result = containers.map((d) => {
        return {
          date: d.date,
          laden_number: d.laden_number,
          container_number: d.container_number,
          container_size: d.container_size,
        };
      });

      const payload = {
        shipping_company: containers[0].shippingLine?.id,
        bay_area: containers[0].bay_area?.id,
        containers: result,
      };

      setLoading(true);

      const response = await api.booking.createBooking(payload);

      setLoading(false);

      if (response.status === 201) {
        Modal.success({
          title: response.data.message,
          onOk: () => {
            dispatch({
              type: "RESET",
              payload: {},
            });

            // navigate("/drop-off");
          },
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Section>
      <div className="max-width-wrapper">
        <div className="title-wrapper">
          <Heading className="title">Your Cart</Heading>

          <Link
            to="/drop-off"
            onClick={(e) => {
              e.preventDefault();

              navigate("/drop-off", {
                state: {
                  isSameContainer: containers.length > 0 ? true : false,
                  from: location.pathname,
                },
              });
            }}
          >
            <AddToCartButton />
          </Link>
        </div>

        <Space>
          {containers.length > 0 ? (
            containers?.map((item: any, idx) => (
              <CollapseWrapper key={idx}>
                <CollapseStyled
                  defaultActiveKey={["1"]}
                  expandIconPosition="right"
                  expandIcon={(props) =>
                    props.isActive ? (
                      <TimesIcon {...props} />
                    ) : (
                      <AddIcon {...props} />
                    )
                  }
                  onChange={() => {}}
                >
                  <Panel header={<strong>MK00{idx + 1}</strong>} key="1">
                    <SummaryItem>
                      <p>Shipping Line:</p>
                      <p>{item?.shippingLine?.name}</p>
                    </SummaryItem>

                    <SummaryItem>
                      <p>Holding Bay:</p>
                      <p>{item?.bay_area?.name}</p>
                    </SummaryItem>

                    <SummaryItem>
                      <p>Anticipated drop off date:</p>
                      <p>{item?.date}</p>
                    </SummaryItem>

                    <SummaryItem>
                      <p>Bill of Laden:</p>
                      <p>{item?.laden_number}</p>
                    </SummaryItem>

                    <SummaryItem>
                      <p>Container Number:</p>
                      <p>{item?.container_number}</p>
                    </SummaryItem>

                    <SummaryItem>
                      <p>Container Size:</p>
                      <p>{item?.container_size}</p>
                    </SummaryItem>
                  </Panel>
                </CollapseStyled>

                <DeleteCartItemBtn onClick={() => removeItemFromCart(idx)}>
                  <TrashIcon />
                </DeleteCartItemBtn>
              </CollapseWrapper>
            ))
          ) : (
            <Empty />
          )}
        </Space>

        {containers.length > 0 ? (
          <ButtonStyled
            loading={loading}
            className="book-now-btn"
            type="primary"
            onClick={handleBooking}
          >
            Book Now
          </ButtonStyled>
        ) : null}
      </div>
    </Section>
  );
};

export default Cart;

const Space = styled.div<{ size?: string }>`
  display: flex;
  flex-direction: column;
  ${({ size }) => (size ? size : `gap: 24px;`)}
`;
