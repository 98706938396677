import React, { useState } from "react";
import { Section } from "pages/dashboard/styles";
import Heading from "components/common/heading";
import api from "api";
import { Form, Modal } from "antd";
import ButtonStyled from "components/common/buttonStyled";
import InputStyled from "components/common/inputStyled";
import CustomError from "components/common/customError";

const FormItem = Form.Item;

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiErrors, setApiErrors] = useState<string[]>([]);

  const [form] = Form.useForm();

  const handleSubmit = async () => {
    setApiErrors([]);
    try {
      const values = await form.validateFields();

      setIsLoading(true);

      const response = await api.auth.forgotPassword(values.email);

      console.log(response);

      if (response.status >= 200) {
        Modal.success({
          title: response.data.message,
          content: "Password reset link sent successfully.",
        });
      }

      setIsLoading(false);
    } catch (err: any) {
      console.log(err);
      setIsLoading(false);

      let errorMsg: string[] = [];

      Object.values(err.response.data).forEach((error) => {
        if (Array.isArray(error)) {
          error.forEach((e) => errorMsg.push(e));
        }

        if (typeof error === "string") {
          errorMsg.push(error);
        }
      });

      setApiErrors(errorMsg);
    }
  };

  return (
    <Section>
      <div className="max-width-wrapper">
        <Heading className="title">Forgot Password</Heading>
        <p>Enter email to receive password reset link.</p>

        <Form
          name="forgot-password"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <FormItem
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not a valid E-mail!",
              },
              {
                message: "Please input your Email!",
                required: true,
              },
            ]}
          >
            <InputStyled placeholder="Email Address" />
          </FormItem>

          <FormItem className="btn-signin-wrapper">
            <ButtonStyled
              className="btn-signin"
              htmlType="submit"
              type="primary"
              size="large"
              full
              loading={isLoading}
            >
              Send
            </ButtonStyled>
          </FormItem>
        </Form>

        {apiErrors.length > 0 ? (
          <CustomError>
            {apiErrors.map((error, i) => (
              <li key={error.substr(0, 4) + i} className="text-sm">
                {error}
              </li>
            ))}
          </CustomError>
        ) : null}
      </div>
    </Section>
  );
};

export default ForgotPassword;
