import React, { FC } from "react";
import * as headings from "./headingStyle";

type HeadingProps = {
  as?: "H2" | "H3";
  className?: string;
  id?: string;
};

const Heading: FC<HeadingProps> = ({
  as = "H2",
  className,
  id,
  children,
  ...rest
}) => {
  const StyledHeading = as ? headings[as] : headings.H2;

  return (
    <StyledHeading className={className} id={id} {...rest}>
      {children}
    </StyledHeading>
  );
};

export default Heading;
