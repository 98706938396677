import React, { FC } from "react";
import styled from "styled-components";

type Props = {
  menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
};

const CustomSelectDropdown: FC<Props> = ({ menu }) => {
  return <DropdownWrapper>{menu}</DropdownWrapper>;
};

export default CustomSelectDropdown;

const DropdownWrapper = styled.div`
  /* background: var(--bg-white); */
  /* border: 1px solid #dbdee7;
  border-radius: 0px 0px 5px 5px; */

  /* ant-select-item ant-select-item-option ant-select-item-option-active ant-select-item-option-selected */

  .ant-select-item {
    padding-left: 24px;
  }
`;
