import React, { useEffect, useState } from "react";
import { Table, Button } from "antd";
import Card from "components/common/card";
import api from "api";
import useToggle from "components/hooks/useToggle";
import ActivityModal from "./activityModal";

const data = {
  "2022-04-19": [
    {
      id: "3da3c141-7f0c-41c9-bc8b-9ddb4ceab880",
      shipping_company_detail: {
        name: "Sifax Shipping Line",
        is_active: true,
      },
      bay_area_detail: {
        name: "Apapa Holding bay",
        address: "13, Apapa Iganmu Road, Lagos State",
        shipping_company: "bde35ac8-1c04-4538-859f-8de3239833a7",
        available_space: 28,
        threshold: 0,
        is_active: true,
      },
      date: "2022-04-19",
      laden_number: "3792738",
      container_number: "029092",
      container_size: "40 feet",
      drop_off: "DYd7qZ",
      status: "pending",
      is_active: true,
      date_created: "2022-03-31T22:19:46.914733+01:00",
      user: "667a1350-6aa4-4dc3-b72d-c951a3bc0d23",
      shipping_company: "bde35ac8-1c04-4538-859f-8de3239833a7",
      bay_area: "e8cb6ad4-9892-426b-afe6-b7d340eee89d",
    },
  ],
  "2022-04-01": [
    {
      id: "abc01800-a2d8-404c-8e35-c14d1066b548",
      shipping_company_detail: {
        name: "Sifax Shipping Line",
        is_active: true,
      },
      bay_area_detail: {
        name: "Apapa Holding bay",
        address: "13, Apapa Iganmu Road, Lagos State",
        shipping_company: "bde35ac8-1c04-4538-859f-8de3239833a7",
        available_space: 28,
        threshold: 0,
        is_active: true,
      },
      date: "2022-04-01",
      laden_number: "9830982",
      container_number: "093209",
      container_size: "20 feet",
      drop_off: "soll9P",
      status: "pending",
      is_active: true,
      date_created: "2022-03-31T22:19:46.914657+01:00",
      user: "667a1350-6aa4-4dc3-b72d-c951a3bc0d23",
      shipping_company: "bde35ac8-1c04-4538-859f-8de3239833a7",
      bay_area: "e8cb6ad4-9892-426b-afe6-b7d340eee89d",
    },
  ],
  "2022-03-26": [
    {
      id: "d189679f-6c81-488f-a676-6d58f8248eed",
      shipping_company_detail: {
        name: "Sifax Shipping Line",
        is_active: true,
      },
      bay_area_detail: {
        name: "Ikorodu Holding Bay",
        address: "25, Ahmadu Bello Way, Zungeru, Kaduna",
        shipping_company: "bde35ac8-1c04-4538-859f-8de3239833a7",
        available_space: 26,
        threshold: 0,
        is_active: true,
      },
      date: "2022-03-26",
      laden_number: "787989",
      container_number: "62872",
      container_size: "20 feet",
      drop_off: "hPgjCI",
      status: "pending",
      is_active: true,
      date_created: "2022-03-26T10:44:50.619216+01:00",
      user: "667a1350-6aa4-4dc3-b72d-c951a3bc0d23",
      shipping_company: "bde35ac8-1c04-4538-859f-8de3239833a7",
      bay_area: "b86f4b44-568f-4069-9d33-73ee2bc10dbf",
    },
    {
      id: "4c58cbc2-ca6a-478c-8b3e-4458c122ba84",
      shipping_company_detail: {
        name: "Sifax Shipping Line",
        is_active: true,
      },
      bay_area_detail: {
        name: "Ikorodu Holding Bay",
        address: "25, Ahmadu Bello Way, Zungeru, Kaduna",
        shipping_company: "bde35ac8-1c04-4538-859f-8de3239833a7",
        available_space: 26,
        threshold: 0,
        is_active: true,
      },
      date: "2022-03-26",
      laden_number: "787989",
      container_number: "62872",
      container_size: "20 feet",
      drop_off: "AAVLjS",
      status: "pending",
      is_active: true,
      date_created: "2022-03-26T10:44:50.619186+01:00",
      user: "667a1350-6aa4-4dc3-b72d-c951a3bc0d23",
      shipping_company: "bde35ac8-1c04-4538-859f-8de3239833a7",
      bay_area: "b86f4b44-568f-4069-9d33-73ee2bc10dbf",
    },
  ],
  "2022-03-28": [
    {
      id: "9ab5acbb-6257-4d55-9f53-7275f2983022",
      shipping_company_detail: {
        name: "Sifax Shipping Line",
        is_active: true,
      },
      bay_area_detail: {
        name: "Ikorodu Holding Bay",
        address: "25, Ahmadu Bello Way, Zungeru, Kaduna",
        shipping_company: "bde35ac8-1c04-4538-859f-8de3239833a7",
        available_space: 26,
        threshold: 0,
        is_active: true,
      },
      date: "2022-03-28",
      laden_number: "787989",
      container_number: "62872",
      container_size: "20 feet",
      drop_off: "Mcq0Qf",
      status: "pending",
      is_active: true,
      date_created: "2022-03-26T10:44:50.619154+01:00",
      user: "667a1350-6aa4-4dc3-b72d-c951a3bc0d23",
      shipping_company: "bde35ac8-1c04-4538-859f-8de3239833a7",
      bay_area: "b86f4b44-568f-4069-9d33-73ee2bc10dbf",
    },
    {
      id: "aac860ef-bc8c-4de3-9596-4b9fe39a234a",
      shipping_company_detail: {
        name: "Sifax Shipping Line",
        is_active: true,
      },
      bay_area_detail: {
        name: "Ikorodu Holding Bay",
        address: "25, Ahmadu Bello Way, Zungeru, Kaduna",
        shipping_company: "bde35ac8-1c04-4538-859f-8de3239833a7",
        available_space: 26,
        threshold: 0,
        is_active: true,
      },
      date: "2022-03-28",
      laden_number: "787989",
      container_number: "62872",
      container_size: "20 feet",
      drop_off: "lKiRcp",
      status: "pending",
      is_active: true,
      date_created: "2022-03-26T10:44:50.619093+01:00",
      user: "667a1350-6aa4-4dc3-b72d-c951a3bc0d23",
      shipping_company: "bde35ac8-1c04-4538-859f-8de3239833a7",
      bay_area: "b86f4b44-568f-4069-9d33-73ee2bc10dbf",
    },
    {
      id: "3a67b3e1-b4e3-4f25-8ed5-d7903519862f",
      shipping_company_detail: {
        name: "Sifax Shipping Line",
        is_active: true,
      },
      bay_area_detail: {
        name: "Apapa Holding bay",
        address: "13, Apapa Iganmu Road, Lagos State",
        shipping_company: "bde35ac8-1c04-4538-859f-8de3239833a7",
        available_space: 28,
        threshold: 0,
        is_active: true,
      },
      date: "2022-03-28",
      laden_number: "87237",
      container_number: "7439823",
      container_size: "20 feet",
      drop_off: "icXSPN",
      status: "pending",
      is_active: true,
      date_created: "2022-03-26T00:43:36.296018+01:00",
      user: "667a1350-6aa4-4dc3-b72d-c951a3bc0d23",
      shipping_company: "bde35ac8-1c04-4538-859f-8de3239833a7",
      bay_area: "e8cb6ad4-9892-426b-afe6-b7d340eee89d",
    },
  ],
};

const Activity = () => {
  const [loading, setLoading] = useState(false);
  const [userHistory, setUserHistory] = useState<any>({});
  const [currentDate, setCurrentDate] = useState("");

  const [isActivityModalOpen, toggleActivityModal] = useToggle(false);

  const fetchUserHistory = async () => {
    try {
      setLoading(true);
      const response = await api.booking.getMyBookings();

      setLoading(false);

      if (response.status === 200) {
        setUserHistory(response.data.data);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserHistory();
  }, []);

  const columns: any = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: () => <span>You booked a Container</span>,
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },

    {
      title: "",
      key: "action",
      align: "center",
      render: (record: { date: string }) => {
        return (
          <Button
            type="link"
            onClick={() => {
              setCurrentDate(record.date);
              toggleActivityModal();
            }}
          >
            View
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Card title="View your Activities">
        <Table
          loading={loading}
          columns={columns}
          dataSource={Object.keys(userHistory)?.map((d) => ({ date: d }))}
        />
      </Card>

      {isActivityModalOpen ? (
        <ActivityModal
          isOpen={isActivityModalOpen}
          closeModal={toggleActivityModal}
          currentDate={currentDate}
          history={userHistory[currentDate]}
        />
      ) : null}
    </>
  );
};

export default Activity;
