import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { IUserProps } from "api/auth";
import api from "api";

type ActionType = {
  type: "USER_LOGIN" | "USER_DETAILS" | "USER_LOGOUT";
  payload: any;
};

type ContextProps = {
  isAuthenticated: boolean;
  userInfo: IUserProps;
  dispatch: React.Dispatch<ActionType>;
};

type StateProps = Omit<ContextProps, "dispatch">;

export const AuthContext = createContext<ContextProps | undefined>(undefined);

const initialState = {
  isAuthenticated: localStorage.getItem("userInfo") ? true : false,
  userInfo: localStorage.getItem("userInfo")
    ? (JSON.parse(localStorage.getItem("userInfo")!) as IUserProps)
    : null,
};

function reducer(state: StateProps, action: ActionType) {
  switch (action.type) {
    case "USER_LOGIN":
      return {
        ...state,
        isAuthenticated: action.payload ? true : false,
        userInfo: action.payload,
      };

    case "USER_DETAILS":
      return {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload },
      };

    case "USER_LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        userInfo: null,
      };

    default:
      return state;
  }
}

export const AuthProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const response = await api.auth.getUserDetails();

      if (response.status === 200) {
        dispatch({ type: "USER_DETAILS", payload: response.data });
      }
    };

    if (state.isAuthenticated) {
      fetchUserDetails();
    }
  }, [state.isAuthenticated]);

  const value = {
    isAuthenticated: state.isAuthenticated,
    userInfo: state.userInfo,
    dispatch,
  } as ContextProps;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuthContext() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuthContext must be used within a AuthProvider");
  }

  return context;
}
