import { ActionType, StateProps } from "./types";

const cartData = JSON.parse(localStorage.getItem("cartData")!);

export const initialState = {
  containers: cartData ?? [],
  summaryDetails: {},
} as StateProps;

function reducer(state: StateProps, action: ActionType) {
  switch (action.type) {
    case "ADD_TO_CART":
      return {
        ...state,
        containers: [...state.containers, action.payload],
      };

    case "REMOVE_ITEM_FROM_CART":
      const remainingItems = state.containers.filter(
        (item, itemIndex) => itemIndex !== action.payload
      );

      localStorage.setItem("cartData", JSON.stringify(remainingItems));

      return {
        ...state,
        containers: remainingItems,
      };

    case "ADD_SUMMARY":
      return {
        ...state,
        summaryDetails: action.payload,
      };

    case "RESET":
      return initialState;

    default:
      return state;
  }
}

export default reducer;
