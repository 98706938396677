import styled, { css } from "styled-components";

const Div = styled.div<{ bg?: string }>`
  background-color: ${({ bg }) => bg || "#f5f5f5"};
  padding: 2rem 4rem;
`;

const Header = styled.header`
  padding: 2rem 4rem;

  @media screen and (max-width: 1024px) {
    padding: 2rem 1.5rem;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const baseUlStyles = css`
  list-style: none;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  gap: 1.5rem;

  li {
    a {
      color: black;

      &:hover {
        color: ${({ theme }) => theme["primary-color"]};
      }

      &.active {
        color: ${({ theme }) => theme["primary-color"]};
      }
    }
  }
`;

const baseBtnStyles = css`
  height: 41px;
  width: 75px;
  border: none;
  cursor: pointer;
  background: #f5f6f8;
  border-radius: 100px;
`;

const AccountButton = styled.button`
  ${baseBtnStyles}

  width: 130px;
  display: flex;
  align-items: center;
  gap: 0.8rem;

  .user-icon {
    width: 31px;
    height: 31px;
    background-color: skyblue;
    border-radius: 50px;
  }
`;

const CartButton = styled.button`
  ${baseBtnStyles}
`;

const NavItemWrapper = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const NavItems = styled.ul`
  ${baseUlStyles}
`;

const AuthOptions = styled.ul`
  ${baseUlStyles}
`;

const DropdownItemStyled = styled.li`
  position: relative;
  cursor: pointer;

  .content {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    background: #ffffff;
    border: 1px solid #dbdee7;
    border-radius: 6px;
    position: absolute;
    right: 0;
    width: 200px;
    margin-top: 5px;
    z-index: 10;

    li {
      padding: 0.8rem 1.5rem;
      cursor: pointer;

      &:hover {
        background-color: #f5f6f8;
        color: ${({ theme }) => theme["primary-color"]};
      }
    }
  }
`;

const Main = styled.main`
  min-height: calc(100vh - (92px + 150px));
  position: relative;

  .footer-img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
  }
`;

const StyledToggleButton = styled.button`
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;

  @media screen and (min-width: 1025px) {
    display: none;
  }
`;

const Footer = styled.footer<{ borderless?: boolean }>`
  border-top: ${({ borderless }) => !borderless && `solid 4px #c7c7c7`};
  background-color: #f5f5f5;
  min-height: 150px;
  padding: 3rem 0;

  .content-wrapper {
    max-width: 992px;
    margin: auto;
    padding: 0 1rem;

    header {
      margin-bottom: 1rem;
    }

    p {
      font-size: 14px;
    }
  }
`;

const ImagePlaceholder = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50px;
  background-color: #d9d9d9;
`;

export {
  Header,
  Nav,
  Div,
  AccountButton,
  AuthOptions,
  CartButton,
  NavItemWrapper,
  NavItems,
  StyledToggleButton,
  DropdownItemStyled,
  Main,
  Footer,
  ImagePlaceholder,
};
