import React, { FC } from "react";
import styled from "styled-components";

type CardProps = {
  title?: string;
  extra?: string;
  height?: number;
};

const StyledWrapper = styled.div<{ height?: number }>`
  ${(props) => props.height && `height: ${props.height}px;`}

  width: 100%;
  background: #fff;
  border-radius: 12px;

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.2rem;

    .title {
      width: 100%;
      padding: 21px 38px;
      border-bottom: 1px solid #dadada;

      h2 {
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 0;
      }
    }

    .extra {
    }
  }

  .card-body {
    padding: 21px 38px;

    .table-responsive .ant-table-content {
      display: block;
      width: 100%;
      overflow-x: auto;
    }
  }
`;

const Card: FC<CardProps> = ({ title, extra, height, children }) => {
  return (
    <StyledWrapper height={height}>
      <div className="card-header">
        {title ? (
          <div className="title">
            <h2>{title}</h2>
          </div>
        ) : null}

        {extra ? <div className="extra">{extra}</div> : null}
      </div>

      <div className="card-body">{children}</div>
    </StyledWrapper>
  );
};

export default Card;
