import React, { useEffect, useState } from "react";
import { Form, message, Select } from "antd";
import Card from "components/common/card";
import InputStyled from "components/common/inputStyled";
import CustomSelect from "components/common/customSelect";
import { ReactComponent as UserIcon } from "assets/svgs/user.svg";
import { ReactComponent as SelectDropdownIcon } from "assets/svgs/select-dropdown.svg";
import CustomSelectDropdown from "components/common/customSelectDropdown";
import ButtonStyled from "components/common/buttonStyled";
import api from "api";
import { useAuthContext } from "context/auth";

const FormItem = Form.Item;
const Option = Select.Option;

const Settings = () => {
  const [loading, setLoading] = useState(false);

  const { userInfo, dispatch } = useAuthContext();

  const [form] = Form.useForm();

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      console.log(values);

      setLoading(true);

      const response = await api.auth.updateUser({ role: "user", ...values });

      if (response.status === 200) {
        setLoading(false);

        dispatch({ type: "USER_DETAILS", payload: response.data });

        message.success("User details updated successfully.");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <Card title="Settings">
      <div className="view">
        <Form
          name="settings"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          initialValues={{
            first_name: userInfo.first_name,
            last_name: userInfo.last_name,
            user_type: userInfo.user_type,
            company_name: userInfo.company_name,
            email: userInfo.email,
            phone: userInfo.phone,
            address: userInfo.address,
          }}
        >
          <FormItem
            label="First Name"
            name="first_name"
            rules={[
              {
                message: "Please input your first name",
                required: true,
              },
            ]}
          >
            <InputStyled placeholder="First Name" />
          </FormItem>

          <FormItem
            label="Last Name"
            name="last_name"
            rules={[
              {
                message: "Please input your last name",
                required: true,
              },
            ]}
          >
            <InputStyled placeholder="Last Name" />
          </FormItem>

          <FormItem
            label="Customer Type"
            name="user_type"
            rules={[
              {
                message: "Please select customer type",
                required: true,
              },
            ]}
          >
            <CustomSelect
              prefixIcon={<UserIcon />}
              suffixIcon={<SelectDropdownIcon />}
              dropdownRender={(menu) => <CustomSelectDropdown menu={menu} />}
              dropdownClassName="custom-dropdown-class"
            >
              <Option value="Consignee">Consignee</Option>
              <Option value="Transporter">Transporter</Option>
              <Option value="Clearing Agent">Clearing Agent</Option>
              <Option value="Other">Other</Option>
            </CustomSelect>
          </FormItem>

          <FormItem label="Company Name" name="company_name">
            <InputStyled placeholder="Company Name" />
          </FormItem>

          <FormItem
            label="Phone"
            name="phone"
            rules={[
              {
                message: "Please input your phone number !",
                required: true,
              },
            ]}
          >
            <InputStyled placeholder="Phone Number" />
          </FormItem>

          <FormItem
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not a valid E-mail!",
              },
              {
                message: "Please input your Email!",
                required: true,
              },
            ]}
          >
            <InputStyled placeholder="Email Address" />
          </FormItem>

          <FormItem
            label="Address"
            name="address"
            rules={[
              {
                message: "Please input your address",
                required: true,
              },
            ]}
          >
            <InputStyled placeholder="Address" />
          </FormItem>

          <ButtonStyled
            className="btn-save"
            htmlType="submit"
            type="primary"
            size="large"
            full
            loading={loading}
          >
            Save Changes
          </ButtonStyled>
        </Form>
      </div>
    </Card>
  );
};

export default Settings;
