import React, { useState } from "react";
import { Section } from "pages/dashboard/styles";
import Heading from "components/common/heading";
import api from "api";
import { Button, Form, Modal } from "antd";
import ButtonStyled from "components/common/buttonStyled";
import InputStyled from "components/common/inputStyled";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as CheckmarkIcon } from "assets/svgs/checkmark.svg";
import styled from "styled-components";



const FormItem = Form.Item;

const VerifyAccount = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);


  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      setIsLoading(true);

      const response = await api.auth.verifyAccount(values.otp);

      if (response.status === 200) {
        // Modal.success({
        //   title: response.data.message,
        //   content: "Click on the button below to Login.",
        //   okText: "Login",
        //   onOk: () => navigate("/login"),
        // });
        setIsModalOpen(true);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <Section>
       <Modal visible={isModalOpen} footer={null}>
        <ModalWrapper>
          <CheckMarkHalo>
            <CheckmarkIcon width={50} />
          </CheckMarkHalo>
          <SuccessText>Successful!</SuccessText>
          <p>You have successfully created an account!</p>
          <Button
            type="primary"
            size="small"
            style={{ width: "60%" }}
            onClick={() => setIsModalOpen(false)}
          >
            <Link to="/login">Login</Link>
          </Button>
        </ModalWrapper>
      </Modal>
      <div className="max-width-wrapper">
        <Heading className="title">Verify Account</Heading>
        <p>Enter OTP Code you received in your email.</p>

        <Form
          name="verifyAccount"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <FormItem
            label="OTP Code"
            name="otp"
            rules={[
              {
                message: "Enter OTP Code!",
                required: true,
              },
            ]}
          >
            <InputStyled placeholder="Enter OTP Code" />
          </FormItem>

          <FormItem className="btn-signin-wrapper">
            <ButtonStyled
              className="btn-signin"
              htmlType="submit"
              type="primary"
              size="large"
              full
              loading={isLoading}
            >
              Verify
            </ButtonStyled>
          </FormItem>
        </Form>
      </div>
    </Section>
  );
};

export default VerifyAccount;

const ModalWrapper = styled.div`
  align-items: center;
  text-align: center;
  dislay: flex;
  justify-content: center;
  padding: 2rem 0;
`;

const CheckMarkHalo = styled.span`
  height: 70px;
  width: 70px;
  background-color: #adf1cf;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const SuccessText = styled.h2``;
